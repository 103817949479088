import { Box, Grid, Typography, useTheme } from "@mui/joy";
import './styles.css';
import { useEffect, useState } from "react";

const Right = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_719_2034)">
                <path d="M11 8L14.2929 11.2929C14.6834 11.6834 14.6834 12.3166 14.2929 12.7071L11 16M1 12C1 5.92487 5.92487 0.999999 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 0.999999 18.0751 1 12Z" stroke="#F9FBFC" strokeWidth="2" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_719_2034">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

const Left = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_719_2127)">
                <path d="M13 8L9.7071 11.2929C9.31658 11.6834 9.31658 12.3166 9.70711 12.7071L13 16M1 12C1 5.92487 5.92487 0.999999 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 0.999999 18.0751 1 12Z" stroke="#F9FBFC" strokeWidth="2" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_719_2127">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const Reload = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 5H7C4.79086 5 3 6.79086 3 9V15C3 17.2091 4.79086 19 7 19H17C19.2091 19 21 17.2091 21 15V9C21 6.79086 19.2091 5 17 5H15.9375M8 8L10.2929 5.70711C10.6834 5.31658 10.6834 4.68342 10.2929 4.29289L8 2" stroke="#F9FBFC" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

const Cards = ({ title, text1, text2, index, change, select }) => {

    const theme = useTheme();
    const [isActive, setIsActive] = useState(false); 

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsActive(select === index);
        }, 100);

        return () => clearTimeout(timeoutId); // Limpiar el timeout en el desmontaje del componente
    }, [select, index]);

    return (
        <>
            <Box position="relative" className={`card ${isActive ? "active" : ""}`} sx={{ display: { xs: "none", lg: "block" }, width: { lg: select === index ? "900px" : "265px", xl: select === index ? "1300px" : "350px" }, height: "204px", background: select === index ? theme.vars.palette.background.body : "#F0F2FF" }}>
                <Box p={4}>
                    {
                        select === index ? (
                            <Box>
                                <Grid container columns={5} sx={{ flexGrow: 1, height: { xs: "100%" } }}>
                                    <Grid lg={1}>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: "white" }}>
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid lg={4}>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: "white" }}>
                                                {text1}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid lg={1}>
                                    </Grid>
                                    <Grid lg={4}>
                                        <Box mt={2}>
                                            <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: "white" }}>
                                                {text2}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box position="absolute" sx={{ bottom: "12px", right: "24px" }}>
                                    {
                                        index === 1 && (
                                            <Box onClick={() => change(index + 1)} sx={{ cursor: "pointer" }}>
                                                <Right />
                                            </Box>
                                        )
                                    }
                                    {
                                        index !== 1 && index !== 4 && (
                                            <Box display="flex">
                                                <Box onClick={() => change(index - 1)} mr={4} sx={{ cursor: "pointer" }}>
                                                    <Left />
                                                </Box>
                                                <Box onClick={() => change(index + 1)} sx={{ cursor: "pointer" }}>
                                                    <Right />
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    {
                                        index === 4 && (
                                            <Box onClick={() => change(1)} sx={{ cursor: "pointer" }}>
                                                <Reload />
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <Box>
                                    <Typography fontFamily="Raleway" sx={{ fontWeight: 600, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: theme.vars.palette.text.main }}>
                                        {title}
                                    </Typography>
                                </Box>
                                <Box onClick={() => change(index)} position="absolute" sx={{ right: "20px", bottom: "12px", cursor: "pointer" }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 8V12M12 12V16M12 12H16M12 12H8M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <Box mb={2} mx={4} position="relative" 
                sx={{ 
                    display: { xs: "block", lg: "none" }, 
                    width: {xs: "85%", sm: "90%"}, 
                    height: select === index ? "500px" : "85px", 
                    background: select === index ? theme.vars.palette.background.body : "#F0F2FF", 
                    maxWidth: "100vw",
                    transition: 'height 0.5s ease-in-out',
                }}>
                <Box p={4}>
                    {
                        select === index ? (
                            <Box>
                                <Grid container columns={1} sx={{ flexGrow: 1, height: { xs: "100%" } }}>
                                    <Grid xs={1}>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: "white" }}>
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={3} mt={3}>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: "white" }}>
                                                {text1}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={1}>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: "white" }}>
                                                {text2}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box position="absolute" sx={{ bottom: "24px", right: "24px" }} display="flex" justifyContent="end">
                                    <Box mt={3} onClick={() => change(0)} sx={{ cursor: "pointer" }}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 11H7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#F9FBFC" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.main }}>
                                        {title}
                                    </Typography>
                                </Box>
                                <Box onClick={() => change(index)} sx={{ cursor: "pointer" }}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 7V11M11 11V15M11 11H15M11 11H7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default Cards;