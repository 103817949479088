import { Box } from "@mui/joy";

const LogoFooter = () => {

    return (
        <>
            <Box display={{ xs: "none", xl: "block" }}>
                <svg width="237" height="56" viewBox="0 0 237 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_84_2359)">
                        <path d="M2.81497 9.63926C4.62966 7.19278 6.87557 5.08881 10.1516 3.60868C13.4037 2.14691 16.4222 1.96342 18.9136 2.89309C21.3871 3.83498 22.9383 5.79829 22.9143 8.59951C22.9083 14.6546 20.4828 21.6882 20.2492 29.8228C20.1534 33.5659 19.9617 36.6668 18.8837 38.838C17.8236 41.0338 15.7514 42.0735 12.4334 42.1958C9.12746 42.3304 6.34853 41.5781 4.4979 40.0062C2.6353 38.4283 1.67105 36.1897 1.37759 33.3151C0.766703 27.0643 0.281588 21.994 0.0120788 16.9787C-0.12567 14.679 1.03022 12.098 2.82096 9.63926H2.81497ZM11.1997 35.6393C13.3677 35.4007 14.1583 33.7983 14.1463 30.8503C14.2541 22.8319 16.1826 15.5598 15.8113 9.54141C15.6795 7.35792 14.1283 6.68514 11.6309 7.65761C9.1095 8.67902 8.01349 10.3059 8.16322 12.3365C8.61839 17.9695 7.58827 24.6179 7.72602 31.9757C7.80388 34.6607 9.06757 35.89 11.2057 35.6393H11.1997Z" fill="#F9FBFC" />
                        <path d="M31.7961 42.4099C29.9575 42.2081 28.1009 42.0368 26.2203 41.8962C19.1951 23.5659 32.3891 11.0827 24.9746 0.153031C27.8014 -0.0793848 30.5923 -0.0426876 33.3054 0.17138C40.7798 8.5567 39.0489 15.046 38.9591 23.2723C41.181 15.1928 45.3674 10.0429 39.8395 0.997068C41.6901 1.30899 43.5048 1.66373 45.2955 2.03071C50.0389 10.6423 48.1703 14.3854 45.7807 20.5812C43.4389 26.5689 41.6123 33.6332 45.5411 44.2264C43.4808 43.9267 41.4266 43.627 39.3663 43.3457C31.2092 28.4283 35.0303 18.8258 33.3713 10.0674C32.2214 18.3549 26.388 28.5384 31.8141 42.4038L31.7961 42.4099Z" fill="#F9FBFC" />
                        <path d="M55.4231 4.17749C60.412 13.8289 59.316 17.2845 56.6748 23.1316C54.2971 28.7341 51.7338 35.1255 55.998 45.6576C53.9318 45.413 51.8895 45.1377 49.8712 44.8564C45.8465 34.1653 47.9307 27.4007 50.3264 21.6026C52.8478 15.5781 54.4289 12.0552 49.5717 2.95425C51.5422 3.38239 53.4886 3.79829 55.4231 4.18361V4.17749Z" fill="#F9FBFC" />
                        <path d="M72.3542 44.9359C71.941 44.3182 71.372 43.1683 71.0845 41.5047C70.6833 39.1561 70.8749 36.9176 71.3001 34.9237C71.6834 33.1806 71.7733 31.9879 71.354 31.1622C70.9408 30.3426 70.0125 29.7677 68.5392 29.413C67.8025 29.2356 67.0659 29.0582 66.3352 28.8747C64.6523 33.1683 62.7897 39.8411 66.982 46.6301C64.808 46.5139 62.6639 46.3488 60.5557 46.153C56.034 35.9695 58.9986 29.7983 61.2984 24.2876C63.9695 18.5261 64.6822 14.9298 59.6394 4.96037C62.5441 5.45578 65.4428 5.84722 68.3535 6.11022C71.3361 6.36098 74.0312 7.63927 76.2411 9.82887C78.4212 12.0062 80.3796 15.0949 81.0204 18.4955C81.23 19.6393 81.3378 20.7218 81.3558 21.7555C81.3558 25.7983 79.044 27.731 75.9417 28.2142C77.2413 29.0338 78.0019 30.0919 78.2295 31.4191C78.4631 32.7524 78.2415 34.3487 77.8282 36.3549C77.4569 38.2203 77.3072 40.2448 77.7624 42.2692C78.0139 43.3885 78.4631 44.2815 78.9482 44.9176C79.4333 45.5598 80.146 46.153 81.1162 46.6546C78.7985 46.7647 76.4927 46.8197 74.2108 46.8197C73.3664 46.202 72.7735 45.5536 72.3602 44.9298L72.3542 44.9359ZM68.3056 24.2998C69.1261 24.5017 69.9406 24.6974 70.7551 24.887C72.0069 25.1806 73.043 25.1377 73.8275 24.7402C74.6061 24.3426 75.1751 23.5108 75.3368 22.1591C75.4745 20.9909 75.4745 19.7616 75.3009 18.4344C75.1092 16.9726 74.5462 15.7738 73.8635 14.9848C73.1747 14.1897 72.2943 13.7065 71.2762 13.5598C70.3479 13.4252 69.4136 13.2723 68.4733 13.1133C70.0424 17.7432 69.6232 21.1072 68.3056 24.2998Z" fill="#F9FBFC" />
                        <path d="M86.8658 6.4038C90.567 16.8747 92.0164 23.6576 90.579 30.0124C89.7645 36.153 86.6082 41.8044 91.7349 45.8105C89.4351 46.0613 87.1353 46.2754 84.8475 46.4344C79.3076 40.9237 83.0567 35.1989 84.3264 29.3396C86.2788 23.2173 85.1469 17.2601 80.9007 6.56282C82.8771 6.53835 84.8654 6.47719 86.8598 6.39768L86.8658 6.4038Z" fill="#F9FBFC" />
                        <path d="M116.14 36.5689C116.566 39.2295 115.637 40.942 113.96 42.0429C112.254 43.15 110.02 43.6699 106.72 44.1041C103.45 44.5139 100.299 44.6668 97.6461 43.9879C95.0049 43.3151 93.3879 41.6882 93.5017 39.1683C93.8251 33.8105 95.9392 27.2539 94.2683 19.0521C93.5616 15.2417 93.1124 11.8778 93.9149 9.30288C94.6875 6.71572 96.6699 5.2356 99.6046 5.28453C102.533 5.32734 105.043 6.60563 107.073 8.18973C109.097 9.81664 110.535 11.8839 111.505 15.3335C111.888 16.7096 112.271 18.1224 112.637 19.5414C110.648 20.2998 108.708 20.942 106.803 21.468C106.534 19.8472 106.211 18.2081 105.845 16.5689C105.162 13.572 103.713 12.2692 101.713 12.5873C99.6884 12.8992 99.2153 14.7157 99.8741 17.8533C101.743 26.4283 100.617 34.0735 100.79 39.2417C100.91 41.1072 102.527 41.6943 105.126 41.4007C107.75 41.0705 108.876 40.202 108.624 38.3304C108.444 36.942 108.319 35.3579 108.181 33.6209C110.481 33.1622 112.846 32.5689 115.344 31.8166C115.655 33.5292 115.925 35.1316 116.152 36.5751L116.14 36.5689Z" fill="#F9FBFC" />
                        <path d="M115.637 52.3182C113.385 52.7035 111.146 53.1806 109.025 53.7616C108.63 51.9757 108.091 50.3059 107.504 48.6974C109.648 47.9757 111.864 47.3518 114.08 46.8075C114.541 48.5995 115.11 50.4099 115.631 52.3182H115.637Z" fill="#F9FBFC" />
                        <path d="M115.476 16.7585C116.368 14.6423 118.099 12.8625 121.411 11.6576C124.687 10.465 128.149 10.5017 130.999 11.7861C133.85 13.0705 135.683 15.468 136.054 18.5873C136.108 18.991 136.15 19.3946 136.186 19.8044C134.114 19.9451 132.023 20.202 129.915 20.5995C129.831 20.0796 129.736 19.5598 129.622 19.046C129.34 17.7677 128.741 16.9053 127.933 16.4772C127.124 16.0491 126.118 15.9879 124.968 16.3243C123.818 16.6607 123.07 17.2173 122.698 17.9512C122.321 18.6913 122.363 19.6454 122.704 20.8503C123.124 22.3304 123.872 23.4986 125.004 24.4038C126.136 25.309 127.693 26.3182 129.574 27.5536C131.137 28.624 132.323 29.676 133.221 30.6301C134.12 31.5842 134.868 32.8014 135.521 34.2998C136.174 35.7983 136.539 37.5781 136.737 39.6576C137.066 43.1928 137.018 46.098 135.994 48.1592C134.976 50.2387 132.97 51.2784 129.736 51.3824C126.508 51.5047 123.609 50.7647 121.339 49.2968C119.075 47.8166 117.542 45.7066 117.123 42.7035C117.021 41.9451 116.937 41.1806 116.865 40.4221C119.153 39.8105 121.369 39.2906 123.513 38.8503C123.543 39.7983 123.597 40.7524 123.693 41.7066C123.956 44.2937 125.489 45.4191 127.753 45.1744C130.047 44.9298 130.766 43.4436 130.508 40.6851C130.347 38.9114 129.867 37.4313 128.939 36.2815C128.005 35.1316 126.645 33.9145 124.717 32.783C123.112 31.8044 121.716 31.0277 120.596 30.3732C119.476 29.7188 118.44 28.8747 117.452 27.8533C116.458 26.8319 115.769 25.5536 115.326 23.9634C114.565 21.2784 114.595 18.8992 115.476 16.7647V16.7585Z" fill="#F9FBFC" />
                        <path d="M155.057 9.81664C156.333 12.3793 156.962 14.6851 157.333 16.7157C155.213 16.2815 153.051 15.8717 150.835 15.5292C152.039 20.8992 150.176 26.2203 150.164 31.6393C149.859 37.1806 151.398 42.8258 152.59 51.6576C150.757 51.4558 148.889 51.2784 146.978 51.1377C145.337 43.0032 144.097 37.1683 144.271 31.4191C144.235 25.7616 145.613 20.1958 144.331 14.7769C142.013 14.6117 139.648 14.5689 137.246 14.6913C136.683 12.8197 135.773 10.9176 134.162 9.05823C141.51 8.12245 148.613 8.75242 155.057 9.81664Z" fill="#F9FBFC" />
                        <path d="M164.688 40.8503C164.951 43.8105 166.059 45.7555 167.946 45.9879C169.839 46.2754 170.707 44.5322 170.605 41.572C169.88 29.5659 175.504 27.4619 169.689 12.4894C171.539 12.8075 173.39 13.101 175.253 13.3518C180.972 28.2937 176.307 30.4955 176.013 41.9206C175.965 45.4986 175.277 48.991 174.055 51.4252C172.839 53.8778 171.12 55.0705 168.461 54.679C165.808 54.3121 163.676 52.4833 162.095 49.6943C160.525 46.9176 159.34 43.4986 158.908 39.8717C156.812 27.9818 163.442 24.257 157.495 10.2264C159.555 10.5934 161.568 10.9787 163.562 11.3579C169.407 26.0429 163.083 28.6913 164.688 40.8442V40.8503Z" fill="#F9FBFC" />
                        <path d="M188.98 14.5078C192.124 14.5934 194.663 15.9757 196.736 18.2692C198.79 20.5506 200.503 23.5842 200.934 26.942C201.898 34.0001 199.365 38.7524 198.257 45.4864C197.712 48.728 196.969 51.4619 195.861 53.2784C194.741 55.101 193.034 56.0613 189.944 55.9879C186.698 55.89 183.536 55.676 180.475 55.3763C179.08 44.0246 180.212 38.6851 181.403 33.2356C183.152 27.5536 182.332 24.3365 179.301 13.835C182.452 14.1653 185.668 14.3854 188.986 14.5017L188.98 14.5078ZM185.476 48.1347C186.554 48.312 187.65 48.4894 188.746 48.6546C189.704 48.8014 190.453 48.5384 191.016 47.9023C191.579 47.2723 191.926 46.1775 192.124 44.7341C193.016 37.468 195.945 33.2417 194.759 26.2876C194.538 24.9237 193.993 23.835 193.28 23.1377C192.567 22.4344 191.693 22.0491 190.699 21.9879C189.555 21.9145 188.417 21.835 187.285 21.7371C190.752 32.3549 185.32 35.7799 185.476 48.1286V48.1347Z" fill="#F9FBFC" />
                        <path d="M209.959 14.2998C211.367 24.1897 211.756 30.9787 210.474 37.4069C209.516 43.7249 206.899 49.6882 209.762 55.3151C207.63 55.5047 205.492 55.6637 203.353 55.7861C200.257 48.6607 203.276 42.8747 204.282 36.8625C205.653 30.7708 205.006 24.4588 202.958 14.5078C205.246 14.4527 207.582 14.3732 209.953 14.2937L209.959 14.2998Z" fill="#F9FBFC" />
                        <path d="M217.518 17.1561C219.284 14.6423 222.183 13.2295 225.848 13.309C229.514 13.3824 232.496 14.6301 234.485 16.3121C236.461 18.0246 237.252 20.2631 236.928 23.8228C236.168 31.572 235.311 39.8166 234.545 45.9023C234.173 48.7463 232.772 50.6668 230.849 51.9512C228.903 53.2417 226.687 53.8533 223.644 54.1775C220.626 54.4772 217.853 54.3549 215.679 53.2662C213.511 52.1958 212.451 50.1041 212.864 47.2478C213.816 41.0643 215.553 34.5322 215.38 26.5567C215.308 22.8931 215.733 19.6637 217.518 17.1622V17.1561ZM223.034 50.5017C225.441 50.2998 226.705 49.2845 226.921 47.101C227.465 41.1255 228.693 33.0277 229.502 24.6546C229.759 21.6209 228.472 20.2509 225.92 20.4894C223.357 20.7218 222.219 22.4772 222.129 25.5659C221.776 34.0185 220.297 41.4925 219.596 47.5842C219.368 49.8044 220.638 50.6851 223.034 50.5017Z" fill="#F9FBFC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_84_2359">
                            <rect width="237" height="56" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{ xs: "none", lg: "block", xl: "none" }}>
                <svg width="205" height="48" viewBox="0 0 205 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.43502 8.26237C4.00469 6.16538 5.94735 4.36197 8.78105 3.0933C11.594 1.84035 14.205 1.68307 16.36 2.47993C18.4995 3.28727 19.8413 4.9701 19.8205 7.37115C19.8154 12.5612 17.7173 18.59 17.5153 25.5625C17.4324 28.7709 17.2666 31.4288 16.3341 33.2899C15.4172 35.1719 13.6248 36.0631 10.7548 36.168C7.8952 36.2833 5.49148 35.6385 3.89072 34.2912C2.27961 32.9386 1.44556 31.0199 1.19172 28.5559C0.663314 23.1981 0.243699 18.8521 0.0105798 14.5533C-0.10857 12.5822 0.891253 10.3698 2.4402 8.26237H2.43502ZM9.68762 30.5481C11.5629 30.3436 12.2468 28.9701 12.2364 26.4432C12.3296 19.5704 13.9977 13.3371 13.6766 8.17849C13.5626 6.30693 12.2209 5.73026 10.0606 6.56381C7.87965 7.4393 6.93164 8.83379 7.06115 10.5743C7.45486 15.4026 6.56382 21.1012 6.68297 27.4078C6.75032 29.7093 7.84339 30.763 9.6928 30.5481H9.68762Z" fill="#F9FBFC" />
                    <path d="M27.5031 36.3515C25.9127 36.1785 24.3068 36.0317 22.6801 35.9111C16.6035 20.1995 28.016 9.49959 21.6026 0.131309C24.0478 -0.0679046 26.4619 -0.0364498 28.8086 0.147036C35.2738 7.33445 33.7766 12.8967 33.6989 19.9478C35.6209 13.0225 39.242 8.60837 34.4604 0.854769C36.0612 1.12213 37.6309 1.4262 39.1798 1.74075C43.2827 9.12213 41.6664 12.3305 39.5994 17.6411C37.5739 22.7735 35.9939 28.8286 39.3922 37.9085C37.6101 37.6516 35.8333 37.3947 34.0512 37.1536C26.9954 24.3672 30.3006 16.1366 28.8656 8.62934C27.8709 15.7329 22.8252 24.4616 27.5187 36.3463L27.5031 36.3515Z" fill="#F9FBFC" />
                    <path d="M47.9399 3.58085C52.2552 11.8535 51.3072 14.8154 49.0226 19.8272C46.966 24.6293 44.7488 30.1077 48.4373 39.1352C46.65 38.9255 44.8835 38.6896 43.1377 38.4485C39.6564 29.2846 41.4592 23.4865 43.5314 18.5166C45.7123 13.3528 47.08 10.3331 42.8786 2.53236C44.583 2.89933 46.2667 3.25582 47.9399 3.58609V3.58085Z" fill="#F9FBFC" />
                    <path d="M62.585 38.5166C62.2276 37.9871 61.7354 37.0016 61.4868 35.5756C61.1397 33.5625 61.3054 31.6438 61.6733 29.9347C62.0048 28.4406 62.0825 27.4183 61.7199 26.7106C61.3624 26.0081 60.5595 25.5153 59.2851 25.2113C58.6479 25.0592 58.0107 24.9072 57.3787 24.7499C55.923 28.4301 54.3119 34.1497 57.9382 39.9688C56.0577 39.8692 54.2031 39.7276 52.3796 39.5599C48.4683 30.8312 51.0326 25.5415 53.0219 20.8181C55.3324 15.8797 55.9489 12.7971 51.587 4.25188C54.0995 4.67652 56.6068 5.01204 59.1245 5.23747C61.7043 5.45241 64.0355 6.54808 65.9471 8.42488C67.8328 10.2912 69.5268 12.9386 70.0811 15.8535C70.2624 16.8338 70.3557 17.7617 70.3712 18.6477C70.3712 22.113 68.3716 23.7696 65.6881 24.1837C66.8122 24.8862 67.4701 25.7932 67.667 26.9308C67.869 28.0736 67.6774 29.4419 67.3199 31.1614C66.9987 32.7604 66.8692 34.4957 67.2629 36.2309C67.4805 37.1903 67.869 37.9557 68.2887 38.5009C68.7083 39.0514 69.3247 39.5599 70.164 39.9898C68.1591 40.0841 66.1647 40.1313 64.1909 40.1313C63.4605 39.6018 62.9476 39.0461 62.5902 38.5114L62.585 38.5166ZM59.083 20.8286C59.7928 21.0016 60.4973 21.1693 61.2018 21.3318C62.2845 21.5835 63.1808 21.5468 63.8594 21.206C64.5329 20.8653 65.025 20.1523 65.1649 18.9937C65.284 17.9924 65.284 16.9386 65.1338 15.801C64.968 14.5481 64.481 13.5206 63.8905 12.8443C63.2947 12.1628 62.5332 11.7486 61.6525 11.6228C60.8496 11.5075 60.0414 11.3764 59.2281 11.2401C60.5854 15.2086 60.2227 18.092 59.083 20.8286Z" fill="#F9FBFC" />
                    <path d="M75.1372 5.48911C78.3387 14.4642 79.5924 20.2781 78.3491 25.725C77.6445 30.9885 74.9144 35.8325 79.3489 39.2663C77.3596 39.4812 75.3703 39.6647 73.3914 39.801C68.5995 35.0776 71.8424 30.1706 72.9407 25.1483C74.6295 19.9006 73.6504 14.7945 69.9775 5.62541C71.687 5.60444 73.4069 5.55202 75.132 5.48387L75.1372 5.48911Z" fill="#F9FBFC" />
                    <path d="M100.459 31.3449C100.827 33.6254 100.024 35.0933 98.5735 36.0369C97.0971 36.9858 95.1647 37.4314 92.3103 37.8037C89.4818 38.1549 86.7569 38.286 84.462 37.704C82.1774 37.1274 80.7787 35.7329 80.8771 33.573C81.1569 28.9806 82.9855 23.3607 81.5402 16.3305C80.9289 13.0645 80.5404 10.1811 81.2346 7.97403C81.9028 5.75647 83.6176 4.48779 86.156 4.52973C88.6892 4.56643 90.8598 5.66211 92.616 7.0199C94.367 8.4144 95.6103 10.1864 96.4495 13.1431C96.781 14.3227 97.1126 15.5337 97.4286 16.7499C95.7087 17.4 94.0302 17.9504 92.3829 18.4013C92.1497 17.012 91.87 15.6071 91.554 14.2021C90.9634 11.6333 89.7097 10.5166 87.9795 10.7892C86.2285 11.0566 85.8192 12.6136 86.3891 15.303C88.0054 22.6529 87.0315 29.206 87.1817 33.6359C87.2853 35.2348 88.684 35.7381 90.9323 35.4865C93.2014 35.2034 94.1753 34.459 93.9577 32.8548C93.8023 31.6647 93.6935 30.3069 93.5744 28.8181C95.5636 28.4249 97.6099 27.9164 99.7701 27.2715C100.04 28.7394 100.273 30.113 100.47 31.3502L100.459 31.3449Z" fill="#F9FBFC" />
                    <path d="M100.024 44.8443C98.0761 45.1746 96.1387 45.5835 94.3048 46.0815C93.9629 44.5507 93.4966 43.1195 92.989 41.7407C94.8436 41.1221 96.7603 40.5874 98.6771 40.1208C99.076 41.6569 99.5681 43.2086 100.019 44.8443H100.024Z" fill="#F9FBFC" />
                    <path d="M99.8841 14.3646C100.656 12.5507 102.153 11.0252 105.018 9.99238C107.852 8.9701 110.846 9.00156 113.312 10.1025C115.778 11.2034 117.363 13.2584 117.684 15.9321C117.731 16.2781 117.767 16.6241 117.798 16.9753C116.006 17.0959 114.198 17.3161 112.374 17.6569C112.302 17.2113 112.219 16.7656 112.12 16.3253C111.877 15.2296 111.359 14.4904 110.659 14.1234C109.96 13.7565 109.09 13.704 108.095 13.9924C107.1 14.2807 106.453 14.7578 106.132 15.3869C105.805 16.0212 105.842 16.839 106.137 17.8718C106.5 19.1405 107.147 20.1418 108.126 20.9177C109.105 21.6936 110.452 22.5586 112.079 23.6175C113.431 24.535 114.457 25.4367 115.234 26.2545C116.011 27.0723 116.658 28.1156 117.223 29.4C117.788 30.6844 118.104 32.2099 118.275 33.9924C118.56 37.0225 118.518 39.5127 117.632 41.2794C116.752 43.0618 115.016 43.9531 112.219 44.0422C109.426 44.147 106.919 43.5127 104.956 42.2545C102.998 40.9858 101.671 39.1772 101.309 36.6031C101.221 35.9531 101.148 35.2978 101.086 34.6477C103.065 34.1234 104.982 33.6778 106.836 33.3004C106.862 34.113 106.909 34.9308 106.992 35.7486C107.22 37.9662 108.546 38.9308 110.504 38.7211C112.488 38.5114 113.11 37.2375 112.887 34.8731C112.747 33.3528 112.333 32.0841 111.53 31.0985C110.722 30.113 109.546 29.0697 107.878 28.0999C106.489 27.2611 105.282 26.5953 104.313 26.0343C103.345 25.4734 102.448 24.7499 101.594 23.8744C100.734 22.9989 100.138 21.9033 99.7546 20.5402C99.0967 18.2388 99.1226 16.1995 99.8841 14.3698V14.3646Z" fill="#F9FBFC" />
                    <path d="M134.122 8.4144C135.225 10.611 135.769 12.5874 136.09 14.3279C134.256 13.9557 132.386 13.6044 130.469 13.3109C131.511 17.9137 129.9 22.4747 129.889 27.1195C129.625 31.8692 130.956 36.708 131.987 44.2781C130.402 44.1051 128.786 43.9531 127.133 43.8325C125.714 36.86 124.641 31.8587 124.792 26.9308C124.761 22.0815 125.952 17.3109 124.843 12.666C122.839 12.5245 120.792 12.4878 118.715 12.5926C118.228 10.9885 117.441 9.35805 116.047 7.76434C122.403 6.96224 128.547 7.50221 134.122 8.4144Z" fill="#F9FBFC" />
                    <path d="M142.452 35.0147C142.68 37.552 143.638 39.2191 145.27 39.4183C146.907 39.6647 147.658 38.1706 147.57 35.6333C146.943 25.3423 151.808 23.5389 146.777 10.7054C148.378 10.978 149.979 11.2296 151.59 11.4445C156.537 24.2519 152.502 26.1392 152.248 35.9321C152.206 38.9989 151.611 41.9924 150.554 44.0789C149.502 46.1811 148.015 47.2034 145.715 46.8679C143.42 46.5533 141.576 44.9858 140.209 42.5953C138.851 40.2152 137.826 37.2846 137.453 34.1759C135.639 23.9845 141.374 20.7919 136.23 8.76565C138.012 9.0802 139.753 9.41047 141.478 9.7355C146.534 22.3227 141.063 24.5926 142.452 35.0094V35.0147Z" fill="#F9FBFC" />
                    <path d="M163.464 12.4354C166.183 12.5088 168.38 13.6936 170.172 15.6595C171.949 17.6149 173.431 20.2152 173.804 23.0933C174.638 29.1431 172.446 33.2165 171.488 38.9884C171.017 41.767 170.374 44.1103 169.416 45.6673C168.447 47.2296 166.971 48.0527 164.298 47.9898C161.49 47.9059 158.755 47.7224 156.107 47.4655C154.9 37.7355 155.879 33.1588 156.91 28.4878C158.423 23.6175 157.713 20.86 155.092 11.8587C157.817 12.1418 160.599 12.3305 163.469 12.4301L163.464 12.4354ZM160.433 41.2584C161.365 41.4105 162.313 41.5625 163.262 41.704C164.09 41.8299 164.738 41.6044 165.225 41.0592C165.712 40.5192 166.012 39.5808 166.183 38.3436C166.955 32.1156 169.488 28.493 168.463 22.5324C168.271 21.3633 167.8 20.4301 167.183 19.8325C166.567 19.2296 165.81 18.8993 164.95 18.8469C163.961 18.784 162.977 18.7158 161.997 18.632C164.997 27.7329 160.298 30.6687 160.433 41.2532V41.2584Z" fill="#F9FBFC" />
                    <path d="M181.611 12.2571C182.828 20.7342 183.165 26.5533 182.056 32.0632C181.227 37.4786 178.963 42.59 181.44 47.4131C179.595 47.5756 177.746 47.7119 175.897 47.8168C173.218 41.7093 175.829 36.7499 176.7 31.5966C177.886 26.3751 177.326 20.9649 175.555 12.4354C177.534 12.3882 179.554 12.32 181.605 12.2519L181.611 12.2571Z" fill="#F9FBFC" />
                    <path d="M188.148 14.7054C189.677 12.5507 192.184 11.3397 195.354 11.4078C198.525 11.4708 201.105 12.5402 202.824 13.9819C204.534 15.4498 205.218 17.3685 204.938 20.4196C204.28 27.0618 203.539 34.1287 202.876 39.3449C202.555 41.7827 201.343 43.4288 199.68 44.5297C197.996 45.6359 196.08 46.1601 193.448 46.438C190.837 46.6949 188.438 46.59 186.558 45.6569C184.683 44.7394 183.766 42.9465 184.123 40.4983C184.947 35.1981 186.449 29.5992 186.299 22.763C186.237 19.6228 186.605 16.8548 188.148 14.7106V14.7054ZM192.919 43.2873C195.002 43.1143 196.095 42.244 196.282 40.3725C196.753 35.2506 197.815 28.3096 198.514 21.1326C198.737 18.5324 197.623 17.358 195.416 17.5625C193.199 17.7617 192.215 19.2663 192.137 21.9137C191.832 29.1588 190.552 35.5651 189.946 40.7866C189.749 42.6896 190.847 43.4445 192.919 43.2873Z" fill="#F9FBFC" />
                </svg>
            </Box>
            <Box display={{ xs: "none", md: "block", lg: "none" }}>
                <svg width="162" height="38" viewBox="0 0 162 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_63_760)">
                        <path d="M1.92398 6.541C3.1644 4.88089 4.69958 3.45319 6.93889 2.44882C9.16182 1.4569 11.2251 1.33239 12.9281 1.96324C14.6189 2.60238 15.6792 3.93462 15.6628 5.83545C15.6587 9.94423 14.0007 14.7171 13.841 20.2369C13.7755 22.7769 13.6445 24.8811 12.9077 26.3545C12.1831 27.8444 10.7666 28.55 8.49863 28.633C6.23885 28.7243 4.33932 28.2138 3.07434 27.1472C1.80116 26.0764 1.14206 24.5574 0.941464 22.6068C0.523897 18.3652 0.192298 14.9246 0.00807718 11.5213C-0.0860803 9.96083 0.704024 8.20941 1.92807 6.541H1.92398ZM7.6553 24.1839C9.13726 24.022 9.67764 22.9346 9.66945 20.9342C9.74314 15.4932 11.0613 10.5585 10.8075 6.47459C10.7175 4.99294 9.65717 4.53641 7.95006 5.19631C6.22657 5.8894 5.4774 6.99338 5.57974 8.37127C5.89087 12.1937 5.18674 16.705 5.2809 21.6978C5.33412 23.5198 6.19791 24.354 7.6594 24.1839H7.6553Z" fill="#F9FBFC" />
                        <path d="M21.7339 28.7782C20.4771 28.6413 19.208 28.5251 17.9226 28.4296C13.1205 15.9912 22.1392 7.52047 17.0711 0.103912C19.0033 -0.0537985 20.911 -0.0288968 22.7655 0.116363C27.8746 5.8064 26.6915 10.2099 26.6301 15.792C28.1489 10.3095 31.0105 6.81492 27.2319 0.676651C28.4969 0.888316 29.7373 1.12903 30.9613 1.37805C34.2036 7.22165 32.9264 9.76162 31.2929 13.9659C29.6923 18.029 28.4436 22.8226 31.1292 30.0109C29.7209 29.8075 28.3167 29.6041 26.9085 29.4132C21.3327 19.2907 23.9445 12.7747 22.8106 6.83152C22.0246 12.4552 18.0372 19.3654 21.7462 28.7741L21.7339 28.7782Z" fill="#F9FBFC" />
                        <path d="M37.884 2.8348C41.2941 9.38395 40.5449 11.7289 38.7396 15.6965C37.1143 19.4982 35.3622 23.8352 38.277 30.982C36.8646 30.816 35.4686 30.6292 34.089 30.4383C31.338 23.1836 32.7626 18.5934 34.4001 14.659C36.1236 10.5709 37.2044 8.18036 33.8843 2.00474C35.2312 2.29526 36.5617 2.57748 37.884 2.83895V2.8348Z" fill="#F9FBFC" />
                        <path d="M49.4571 30.4923C49.1747 30.0731 48.7858 29.2929 48.5893 28.164C48.315 26.5703 48.446 25.0513 48.7366 23.6983C48.9986 22.5154 49.06 21.7061 48.7735 21.1459C48.491 20.5897 47.8565 20.1996 46.8494 19.9589C46.3458 19.8385 45.8423 19.7182 45.3429 19.5936C44.1925 22.5071 42.9193 27.0351 45.785 31.6419C44.2989 31.5631 42.8334 31.451 41.3923 31.3182C38.3015 24.408 40.328 20.2203 41.9 16.4809C43.7258 12.5714 44.213 10.131 40.766 3.36603C42.7515 3.70221 44.7329 3.96783 46.7225 4.14629C48.7612 4.31645 50.6034 5.18386 52.114 6.66966C53.6042 8.14716 54.9428 10.2431 55.3809 12.5506C55.5242 13.3267 55.5978 14.0613 55.6101 14.7627C55.6101 17.5061 54.0299 18.8175 51.9093 19.1454C52.7977 19.7016 53.3176 20.4196 53.4732 21.3202C53.6328 22.2249 53.4813 23.3081 53.1989 24.6694C52.9451 25.9353 52.8427 27.309 53.1538 28.6828C53.3258 29.4423 53.6328 30.0482 53.9644 30.4798C54.296 30.9156 54.7832 31.3182 55.4464 31.6585C53.8621 31.7332 52.286 31.7706 50.7262 31.7706C50.149 31.3514 49.7437 30.9115 49.4612 30.4881L49.4571 30.4923ZM46.6897 16.4892C47.2506 16.6262 47.8073 16.759 48.3641 16.8877C49.2197 17.0869 49.9279 17.0578 50.4642 16.7881C50.9964 16.5183 51.3853 15.9538 51.4959 15.0366C51.59 14.2439 51.59 13.4097 51.4713 12.5091C51.3403 11.5172 50.9555 10.7037 50.4888 10.1683C50.018 9.62881 49.4162 9.30094 48.7203 9.20133C48.0857 9.11003 47.4471 9.00627 46.8044 8.89836C47.8769 12.0401 47.5904 14.3228 46.6897 16.4892Z" fill="#F9FBFC" />
                        <path d="M59.3764 4.3455C61.9064 11.4508 62.8971 16.0535 61.9146 20.3656C61.3578 24.5325 59.2004 28.3673 62.7047 31.0858C61.1327 31.2559 59.5606 31.4012 57.9968 31.5091C54.21 27.7697 56.7728 23.885 57.6407 19.9091C58.9752 15.7546 58.2015 11.7123 55.299 4.45341C56.65 4.43681 58.0091 4.39531 59.3723 4.34135L59.3764 4.3455Z" fill="#F9FBFC" />
                        <path d="M79.3869 24.8147C79.6776 26.6201 79.0431 27.7822 77.8968 28.5292C76.7301 29.2804 75.2031 29.6332 72.9474 29.9279C70.7122 30.2059 68.5588 30.3097 66.7453 29.849C64.9399 29.3925 63.8346 28.2885 63.9124 26.5786C64.1334 22.9429 65.5785 18.4938 64.4364 12.9283C63.9533 10.3427 63.6463 8.06 64.1948 6.31274C64.7229 4.55716 66.078 3.5528 68.0839 3.586C70.0858 3.61505 71.8011 4.48246 73.1889 5.55738C74.5726 6.66136 75.5551 8.06416 76.2183 10.4049C76.4803 11.3387 76.7423 12.2974 76.9921 13.2603C75.6329 13.7749 74.3065 14.2107 73.0047 14.5676C72.8205 13.4678 72.5994 12.3555 72.3497 11.2433C71.883 9.20963 70.8923 8.32562 69.525 8.54144C68.1413 8.7531 67.8178 9.98574 68.2682 12.1148C69.5454 17.9335 68.7758 23.1214 68.8945 26.6284C68.9764 27.8942 70.0817 28.2926 71.8584 28.0934C73.6515 27.8693 74.4212 27.28 74.2492 26.01C74.1264 25.0679 74.0404 23.9929 73.9463 22.8143C75.5183 22.503 77.1354 22.1004 78.8425 21.5899C79.0554 22.752 79.2396 23.8394 79.3951 24.8189L79.3869 24.8147Z" fill="#F9FBFC" />
                        <path d="M79.0431 35.5017C77.5038 35.7632 75.9727 36.0869 74.5235 36.4812C74.2533 35.2693 73.8849 34.1362 73.4837 33.0447C74.9493 32.555 76.464 32.1317 77.9787 31.7623C78.2939 32.9783 78.6828 34.2068 79.039 35.5017H79.0431Z" fill="#F9FBFC" />
                        <path d="M78.9325 11.3719C79.5425 9.93594 80.7256 8.7282 82.9895 7.9106C85.2288 7.10129 87.595 7.12619 89.5437 7.99775C91.4923 8.86931 92.745 10.4962 92.9988 12.6129C93.0357 12.8868 93.0643 13.1607 93.0889 13.4388C91.6724 13.5342 90.2437 13.7085 88.8027 13.9783C88.7454 13.6255 88.6799 13.2728 88.6021 12.9241C88.4097 12.0567 88.0003 11.4715 87.4476 11.181C86.895 10.8905 86.2072 10.849 85.4212 11.0773C84.6352 11.3055 84.1235 11.6832 83.8697 12.1812C83.6117 12.6834 83.6404 13.3309 83.8737 14.1485C84.1603 15.1528 84.672 15.9455 85.4458 16.5598C86.2195 17.174 87.2839 17.8588 88.5693 18.6972C89.6378 19.4235 90.4484 20.1373 91.0625 20.7848C91.6765 21.4322 92.1883 22.2581 92.6345 23.275C93.0807 24.2918 93.3304 25.4995 93.4655 26.9106C93.6907 29.3095 93.6579 31.2808 92.9579 32.6795C92.262 34.0906 90.8905 34.7961 88.6799 34.8667C86.4733 34.9497 84.4919 34.4475 82.9404 33.4514C81.3929 32.4471 80.3449 31.0152 80.0583 28.9774C79.9887 28.4628 79.9314 27.944 79.8823 27.4294C81.4461 27.0144 82.9608 26.6616 84.4264 26.3628C84.4469 27.0061 84.4837 27.6535 84.5492 28.3009C84.7293 30.0565 85.7774 30.8202 87.3248 30.6542C88.8927 30.4881 89.384 29.4796 89.208 27.6078C89.0974 26.4043 88.7699 25.3999 88.1354 24.6196C87.4968 23.8394 86.5675 23.0135 85.2493 22.2457C84.1521 21.5816 83.1983 21.0545 82.4327 20.6105C81.6672 20.1664 80.959 19.5936 80.2835 18.9006C79.6039 18.2075 79.1331 17.34 78.8302 16.261C78.3103 14.439 78.3307 12.8245 78.9325 11.3761V11.3719Z" fill="#F9FBFC" />
                        <path d="M105.988 6.66136C106.86 8.40033 107.29 9.96499 107.544 11.3429C106.095 11.0482 104.617 10.7701 103.102 10.5377C103.925 14.1817 102.652 17.7924 102.644 21.4696C102.435 25.2297 103.487 29.0604 104.302 35.0535C103.049 34.9165 101.772 34.7961 100.466 34.7007C99.3442 29.1808 98.4968 25.2214 98.6155 21.3202C98.591 17.4812 99.5325 13.7044 98.6565 10.0272C97.0722 9.91518 95.4551 9.88613 93.8135 9.96914C93.4287 8.69915 92.8064 7.40841 91.7052 6.14673C96.7283 5.51173 101.584 5.93921 105.988 6.66136Z" fill="#F9FBFC" />
                        <path d="M112.571 27.7199C112.751 29.7286 113.509 31.0484 114.798 31.2061C116.092 31.4012 116.686 30.2184 116.616 28.2096C116.121 20.0626 119.965 18.6349 115.99 8.47504C117.255 8.69085 118.52 8.89006 119.793 9.06023C123.702 19.1994 120.513 20.6935 120.313 28.4462C120.28 30.8741 119.809 33.2439 118.974 34.8957C118.143 36.56 116.968 37.3693 115.15 37.1037C113.337 36.8547 111.879 35.6137 110.799 33.7212C109.726 31.837 108.916 29.517 108.621 27.0559C107.188 18.9877 111.72 16.4602 107.655 6.93943C109.063 7.18845 110.438 7.44992 111.802 7.70723C115.797 17.6721 111.474 19.4691 112.571 27.7157V27.7199Z" fill="#F9FBFC" />
                        <path d="M129.176 9.84463C131.325 9.90273 133.061 10.8407 134.477 12.3971C135.881 13.9451 137.052 16.0037 137.347 18.2822C138.006 23.0716 136.274 26.2964 135.517 30.8658C135.145 33.0655 134.637 34.9206 133.88 36.1533C133.114 37.3901 131.947 38.0417 129.835 37.9919C127.616 37.9254 125.455 37.7802 123.363 37.5768C122.409 29.8739 123.182 26.2507 123.997 22.5528C125.193 18.6972 124.632 16.5141 122.56 9.3881C124.714 9.61221 126.912 9.76162 129.18 9.84048L129.176 9.84463ZM126.781 32.6629C127.518 32.7832 128.267 32.9036 129.016 33.0157C129.671 33.1153 130.183 32.9368 130.568 32.5052C130.952 32.0777 131.19 31.3348 131.325 30.3553C131.935 25.4248 133.937 22.5569 133.126 17.8381C132.975 16.9126 132.602 16.1738 132.115 15.7007C131.628 15.2234 131.03 14.9619 130.351 14.9204C129.569 14.8706 128.791 14.8167 128.017 14.7503C130.388 21.9552 126.674 24.2793 126.781 32.6587V32.6629Z" fill="#F9FBFC" />
                        <path d="M143.516 9.70352C144.478 16.4145 144.745 21.0213 143.868 25.3833C143.213 29.6705 141.424 33.7171 143.381 37.5353C141.924 37.664 140.462 37.7719 139.001 37.8549C136.884 33.0198 138.948 29.0936 139.635 25.0139C140.573 20.8802 140.131 16.5971 138.731 9.84463C140.295 9.80728 141.891 9.75332 143.512 9.69937L143.516 9.70352Z" fill="#F9FBFC" />
                        <path d="M148.683 11.6417C149.89 9.93594 151.872 8.97722 154.377 9.03117C156.883 9.08098 158.921 9.92764 160.281 11.069C161.631 12.231 162.172 13.75 161.951 16.1655C161.431 21.4239 160.845 27.0185 160.321 31.148C160.068 33.0779 159.11 34.3811 157.796 35.2527C156.465 36.1284 154.95 36.5434 152.871 36.7634C150.807 36.9667 148.912 36.8837 147.426 36.145C145.944 35.4187 145.219 33.9993 145.502 32.0611C146.153 27.8652 147.34 23.4327 147.221 18.0207C147.172 15.5347 147.463 13.3433 148.683 11.6459V11.6417ZM152.453 34.2691C154.099 34.1321 154.963 33.4431 155.11 31.9615C155.483 27.9067 156.322 22.4117 156.874 16.73C157.05 14.6714 156.17 13.7417 154.426 13.9036C152.674 14.0613 151.896 15.2524 151.835 17.3483C151.593 23.084 150.582 28.1557 150.103 32.2894C149.948 33.7959 150.816 34.3936 152.453 34.2691Z" fill="#F9FBFC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_63_760">
                            <rect width="162" height="38" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{ xs: "none", sm: "block", md: "none" }}>
                <svg width="162" height="38" viewBox="0 0 162 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.9241 6.541C3.16452 4.88089 4.6997 3.45319 6.93901 2.44882C9.16195 1.4569 11.2252 1.33239 12.9282 1.96324C14.619 2.60238 15.6793 3.93462 15.6629 5.83545C15.6588 9.94423 14.0008 14.7171 13.8412 20.2369C13.7757 22.7769 13.6447 24.8811 12.9078 26.3545C12.1832 27.8444 10.7667 28.55 8.49875 28.633C6.23897 28.7243 4.33944 28.2138 3.07446 27.1472C1.80129 26.0764 1.14218 24.5574 0.941587 22.6068C0.524019 18.3652 0.19242 14.9246 0.00819925 11.5213C-0.0859582 9.96083 0.704146 8.20941 1.92819 6.541H1.9241ZM7.65543 24.1839C9.13738 24.022 9.67776 22.9346 9.66958 20.9342C9.74327 15.4932 11.0615 10.5585 10.8077 6.47459C10.7176 4.99294 9.6573 4.53641 7.95018 5.19631C6.22669 5.8894 5.47752 6.99338 5.57987 8.37127C5.891 12.1937 5.18686 16.705 5.28102 21.6978C5.33424 23.5198 6.19803 24.354 7.65952 24.1839H7.65543Z" fill="#F9FBFC" />
                    <path d="M21.734 28.7782C20.4772 28.6413 19.2081 28.5251 17.9227 28.4296C13.1207 15.9912 22.1393 7.52047 17.0712 0.103912C19.0034 -0.0537985 20.9112 -0.0288968 22.7657 0.116363C27.8747 5.8064 26.6916 10.2099 26.6302 15.792C28.149 10.3095 31.0106 6.81492 27.232 0.676651C28.497 0.888316 29.7374 1.12903 30.9615 1.37805C34.2037 7.22165 32.9265 9.76162 31.2931 13.9659C29.6924 18.029 28.4438 22.8226 31.1293 30.0109C29.721 29.8075 28.3169 29.6041 26.9086 29.4132C21.3328 19.2907 23.9447 12.7747 22.8107 6.83152C22.0247 12.4552 18.0373 19.3654 21.7463 28.7741L21.734 28.7782Z" fill="#F9FBFC" />
                    <path d="M37.8841 2.8348C41.2942 9.38395 40.545 11.7289 38.7397 15.6965C37.1144 19.4982 35.3623 23.8352 38.2771 30.982C36.8647 30.816 35.4687 30.6292 34.0891 30.4383C31.3381 23.1836 32.7627 18.5934 34.4002 14.659C36.1237 10.5709 37.2045 8.18036 33.8844 2.00474C35.2313 2.29526 36.5618 2.57748 37.8841 2.83895V2.8348Z" fill="#F9FBFC" />
                    <path d="M49.4573 30.4923C49.1748 30.0731 48.7859 29.2929 48.5894 28.164C48.3151 26.5703 48.4461 25.0513 48.7368 23.6983C48.9988 22.5154 49.0602 21.7061 48.7736 21.1459C48.4911 20.5897 47.8566 20.1996 46.8495 19.9589C46.346 19.8385 45.8424 19.7182 45.343 19.5936C44.1926 22.5071 42.9195 27.0351 45.7851 31.6419C44.2991 31.5631 42.8335 31.451 41.3925 31.3182C38.3016 24.408 40.3281 20.2203 41.9001 16.4809C43.7259 12.5714 44.2131 10.131 40.7661 3.36603C42.7516 3.70221 44.733 3.96783 46.7226 4.14629C48.7613 4.31645 50.6035 5.18386 52.1141 6.66966C53.6043 8.14716 54.943 10.2431 55.381 12.5506C55.5243 13.3267 55.598 14.0613 55.6102 14.7627C55.6102 17.5061 54.03 18.8175 51.9095 19.1454C52.7978 19.7016 53.3177 20.4196 53.4733 21.3202C53.6329 22.2249 53.4815 23.3081 53.199 24.6694C52.9452 25.9353 52.8428 27.309 53.154 28.6828C53.3259 29.4423 53.6329 30.0482 53.9645 30.4798C54.2961 30.9156 54.7833 31.3182 55.4465 31.6585C53.8622 31.7332 52.2861 31.7706 50.7263 31.7706C50.1491 31.3514 49.7438 30.9115 49.4614 30.4881L49.4573 30.4923ZM46.6898 16.4892C47.2507 16.6262 47.8075 16.759 48.3642 16.8877C49.2198 17.0869 49.928 17.0578 50.4643 16.7881C50.9965 16.5183 51.3854 15.9538 51.496 15.0366C51.5901 14.2439 51.5901 13.4097 51.4714 12.5091C51.3404 11.5172 50.9556 10.7037 50.4889 10.1683C50.0181 9.62881 49.4163 9.30094 48.7204 9.20133C48.0858 9.11003 47.4472 9.00627 46.8045 8.89836C47.8771 12.0401 47.5905 14.3228 46.6898 16.4892Z" fill="#F9FBFC" />
                    <path d="M59.3765 4.3455C61.9065 11.4508 62.8972 16.0535 61.9147 20.3656C61.358 24.5325 59.2005 28.3673 62.7048 31.0858C61.1328 31.2559 59.5608 31.4012 57.9969 31.5091C54.2102 27.7697 56.7729 23.885 57.6408 19.9091C58.9754 15.7546 58.2016 11.7123 55.2991 4.45341C56.6501 4.43681 58.0092 4.39531 59.3725 4.34135L59.3765 4.3455Z" fill="#F9FBFC" />
                    <path d="M79.3871 24.8147C79.6777 26.6201 79.0432 27.7822 77.8969 28.5292C76.7302 29.2804 75.2032 29.6332 72.9475 29.9279C70.7123 30.2059 68.559 30.3097 66.7454 29.849C64.94 29.3925 63.8347 28.2885 63.9125 26.5786C64.1336 22.9429 65.5787 18.4938 64.4365 12.9283C63.9534 10.3427 63.6464 8.06 64.195 6.31274C64.7231 4.55716 66.0781 3.5528 68.0841 3.586C70.0859 3.61505 71.8012 4.48246 73.189 5.55738C74.5728 6.66136 75.5553 8.06416 76.2185 10.4049C76.4805 11.3387 76.7425 12.2974 76.9922 13.2603C75.633 13.7749 74.3067 14.2107 73.0048 14.5676C72.8206 13.4678 72.5995 12.3555 72.3498 11.2433C71.8831 9.20963 70.8924 8.32562 69.5251 8.54144C68.1414 8.7531 67.818 9.98574 68.2683 12.1148C69.5456 17.9335 68.7759 23.1214 68.8946 26.6284C68.9765 27.8942 70.0818 28.2926 71.8586 28.0934C73.6516 27.8693 74.4213 27.28 74.2493 26.01C74.1265 25.0679 74.0406 23.9929 73.9464 22.8143C75.5184 22.503 77.1355 22.1004 78.8426 21.5899C79.0555 22.752 79.2397 23.8394 79.3953 24.8189L79.3871 24.8147Z" fill="#F9FBFC" />
                    <path d="M79.0432 35.5017C77.5039 35.7632 75.9728 36.0869 74.5236 36.4812C74.2534 35.2693 73.885 34.1362 73.4838 33.0447C74.9494 32.555 76.4641 32.1317 77.9788 31.7623C78.294 32.9783 78.6829 34.2068 79.0391 35.5017H79.0432Z" fill="#F9FBFC" />
                    <path d="M78.9326 11.3719C79.5426 9.93594 80.7257 8.7282 82.9896 7.9106C85.2289 7.10129 87.5951 7.12619 89.5438 7.99775C91.4924 8.86931 92.7451 10.4962 92.999 12.6129C93.0358 12.8868 93.0645 13.1607 93.089 13.4388C91.6726 13.5342 90.2438 13.7085 88.8028 13.9783C88.7455 13.6255 88.68 13.2728 88.6022 12.9241C88.4098 12.0567 88.0004 11.4715 87.4478 11.181C86.8951 10.8905 86.2073 10.849 85.4213 11.0773C84.6353 11.3055 84.1236 11.6832 83.8698 12.1812C83.6119 12.6834 83.6405 13.3309 83.8739 14.1485C84.1604 15.1528 84.6722 15.9455 85.4459 16.5598C86.2196 17.174 87.284 17.8588 88.5695 18.6972C89.6379 19.4235 90.4485 20.1373 91.0626 20.7848C91.6767 21.4322 92.1884 22.2581 92.6346 23.275C93.0808 24.2918 93.3306 25.4995 93.4657 26.9106C93.6908 29.3095 93.6581 31.2808 92.958 32.6795C92.2621 34.0906 90.8906 34.7961 88.68 34.8667C86.4734 34.9497 84.492 34.4475 82.9405 33.4514C81.393 32.4471 80.345 31.0152 80.0584 28.9774C79.9888 28.4628 79.9315 27.944 79.8824 27.4294C81.4462 27.0144 82.9609 26.6616 84.4265 26.3628C84.447 27.0061 84.4838 27.6535 84.5493 28.3009C84.7295 30.0565 85.7775 30.8202 87.3249 30.6542C88.8929 30.4881 89.3841 29.4796 89.2081 27.6078C89.0976 26.4043 88.7701 25.3999 88.1355 24.6196C87.4969 23.8394 86.5676 23.0135 85.2494 22.2457C84.1523 21.5816 83.1984 21.0545 82.4328 20.6105C81.6673 20.1664 80.9591 19.5936 80.2836 18.9006C79.604 18.2075 79.1332 17.34 78.8303 16.261C78.3104 14.439 78.3309 12.8245 78.9326 11.3761V11.3719Z" fill="#F9FBFC" />
                    <path d="M105.989 6.66136C106.861 8.40033 107.29 9.96499 107.544 11.3429C106.095 11.0482 104.617 10.7701 103.102 10.5377C103.925 14.1817 102.652 17.7924 102.644 21.4696C102.435 25.2297 103.487 29.0604 104.302 35.0535C103.049 34.9165 101.772 34.7961 100.466 34.7007C99.3444 29.1808 98.4969 25.2214 98.6157 21.3202C98.5911 17.4812 99.5327 13.7044 98.6566 10.0272C97.0723 9.91518 95.4552 9.88613 93.8136 9.96914C93.4288 8.69915 92.8065 7.40841 91.7053 6.14673C96.7284 5.51173 101.584 5.93921 105.989 6.66136Z" fill="#F9FBFC" />
                    <path d="M112.571 27.7199C112.752 29.7286 113.509 31.0484 114.798 31.2061C116.092 31.4012 116.686 30.2184 116.616 28.2096C116.121 20.0626 119.965 18.6349 115.99 8.47504C117.255 8.69085 118.52 8.89006 119.793 9.06023C123.702 19.1994 120.513 20.6935 120.313 28.4462C120.28 30.8741 119.809 33.2439 118.974 34.8957C118.143 36.56 116.968 37.3693 115.151 37.1037C113.337 36.8547 111.88 35.6137 110.799 33.7212C109.726 31.837 108.916 29.517 108.621 27.0559C107.188 18.9877 111.72 16.4602 107.655 6.93943C109.063 7.18845 110.439 7.44992 111.802 7.70723C115.797 17.6721 111.474 19.4691 112.571 27.7157V27.7199Z" fill="#F9FBFC" />
                    <path d="M129.176 9.84463C131.325 9.90273 133.061 10.8407 134.477 12.3971C135.882 13.9451 137.052 16.0037 137.347 18.2822C138.006 23.0716 136.275 26.2964 135.517 30.8658C135.145 33.0655 134.637 34.9206 133.88 36.1533C133.114 37.3901 131.947 38.0417 129.835 37.9919C127.616 37.9254 125.455 37.7802 123.363 37.5768C122.409 29.8739 123.183 26.2507 123.997 22.5528C125.193 18.6972 124.632 16.5141 122.56 9.3881C124.714 9.61221 126.912 9.76162 129.18 9.84048L129.176 9.84463ZM126.781 32.6629C127.518 32.7832 128.267 32.9036 129.016 33.0157C129.671 33.1153 130.183 32.9368 130.568 32.5052C130.953 32.0777 131.19 31.3348 131.325 30.3553C131.935 25.4248 133.937 22.5569 133.126 17.8381C132.975 16.9126 132.602 16.1738 132.115 15.7007C131.628 15.2234 131.03 14.9619 130.351 14.9204C129.569 14.8706 128.791 14.8167 128.017 14.7503C130.388 21.9552 126.675 24.2793 126.781 32.6587V32.6629Z" fill="#F9FBFC" />
                    <path d="M143.517 9.70352C144.479 16.4145 144.745 21.0213 143.869 25.3833C143.214 29.6705 141.425 33.7171 143.381 37.5353C141.924 37.664 140.463 37.7719 139.001 37.8549C136.885 33.0198 138.948 29.0936 139.636 25.0139C140.573 20.8802 140.131 16.5971 138.731 9.84463C140.295 9.80728 141.891 9.75332 143.512 9.69937L143.517 9.70352Z" fill="#F9FBFC" />
                    <path d="M148.683 11.6417C149.891 9.93594 151.872 8.97722 154.377 9.03117C156.883 9.08098 158.921 9.92764 160.281 11.069C161.632 12.231 162.172 13.75 161.951 16.1655C161.431 21.4239 160.846 27.0185 160.322 31.148C160.068 33.0779 159.11 34.3811 157.796 35.2527C156.465 36.1284 154.951 36.5434 152.871 36.7634C150.808 36.9667 148.912 36.8837 147.426 36.145C145.944 35.4187 145.22 33.9993 145.502 32.0611C146.153 27.8652 147.34 23.4327 147.221 18.0207C147.172 15.5347 147.463 13.3433 148.683 11.6459V11.6417ZM152.453 34.2691C154.099 34.1321 154.963 33.4431 155.11 31.9615C155.483 27.9067 156.322 22.4117 156.875 16.73C157.051 14.6714 156.17 13.7417 154.426 13.9036C152.674 14.0613 151.897 15.2524 151.835 17.3483C151.594 23.084 150.582 28.1557 150.103 32.2894C149.948 33.7959 150.816 34.3936 152.453 34.2691Z" fill="#F9FBFC" />
                </svg>
            </Box>
            <Box display={{ xs: "block", sm: "none" }}>
                <svg width="128" height="30" viewBox="0 0 128 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.51903 5.16361C2.49831 3.853 3.71029 2.72587 5.47817 1.93294C7.23312 1.14985 8.86202 1.05155 10.2065 1.54959C11.5413 2.05418 12.3784 3.10595 12.3655 4.6066C12.3622 7.85038 11.0533 11.6184 10.9272 15.9762C10.8755 17.9814 10.7721 19.6426 10.1904 20.8058C9.6183 21.9821 8.50004 22.5391 6.70954 22.6046C4.92551 22.6767 3.42588 22.2737 2.42721 21.4316C1.42207 20.5863 0.90173 19.3871 0.743364 17.8471C0.413705 14.4985 0.151917 11.7822 0.00647952 9.09546C-0.0678553 7.86348 0.555911 6.48078 1.52226 5.16361H1.51903ZM6.04376 19.0922C7.21373 18.9644 7.64035 18.1059 7.63388 16.5267C7.69206 12.2311 8.73275 8.3353 8.53236 5.11119C8.46126 3.94146 7.62419 3.58104 6.27646 4.10201C4.91581 4.6492 4.32437 5.52076 4.40516 6.60857C4.65079 9.62626 4.0949 13.1879 4.16923 17.1295C4.21125 18.5679 4.89319 19.2265 6.04699 19.0922H6.04376Z" fill="#F9FBFC" />
                    <path d="M17.1584 22.7193C16.1662 22.6112 15.1643 22.5194 14.1495 22.4441C10.3584 12.6243 17.4784 5.93688 13.4772 0.0817019C15.0027 -0.0428066 16.5088 -0.0231474 17.9729 0.0915315C22.0064 4.58367 21.0723 8.06008 21.0239 12.467C22.2229 8.13871 24.482 5.37987 21.4989 0.533864C22.4976 0.700968 23.4769 0.891007 24.4433 1.0876C27.003 5.70097 25.9946 7.70621 24.705 11.0253C23.4414 14.2331 22.4556 18.0175 24.5758 23.6924C23.464 23.5319 22.3554 23.3714 21.2436 23.2206C16.8417 15.2291 18.9037 10.085 18.0084 5.39297C17.3879 9.83269 14.24 15.2881 17.1681 22.716L17.1584 22.7193Z" fill="#F9FBFC" />
                    <path d="M29.9085 2.23766C32.6007 7.40804 32.0093 9.25929 30.584 12.3917C29.3009 15.393 27.9176 18.817 30.2188 24.4592C29.1037 24.3281 28.0016 24.1807 26.9125 24.0299C24.7406 18.3025 25.8653 14.6787 27.1581 11.5725C28.5187 8.34513 29.372 6.45785 26.7509 1.58236C27.8142 1.81171 28.8646 2.03452 29.9085 2.24094V2.23766Z" fill="#F9FBFC" />
                    <path d="M39.0452 24.0725C38.8222 23.7416 38.5152 23.1256 38.36 22.2344C38.1435 20.9762 38.2469 19.777 38.4764 18.7088C38.6832 17.775 38.7317 17.1361 38.5055 16.6938C38.2825 16.2547 37.7815 15.9467 36.9865 15.7567C36.5889 15.6616 36.1914 15.5666 35.7971 15.4683C34.8889 17.7685 33.8838 21.3432 36.1461 24.9801C34.9729 24.9179 33.8159 24.8294 32.6783 24.7246C30.2381 19.2691 31.838 15.9631 33.079 13.0109C34.5205 9.92443 34.9051 7.99782 32.1838 2.65706C33.7513 2.92246 35.3155 3.13216 36.8863 3.27305C38.4958 3.40739 39.9502 4.09219 41.1427 5.26519C42.3192 6.43163 43.376 8.08629 43.7218 9.90804C43.835 10.5208 43.8931 11.1007 43.9028 11.6544C43.9028 13.8202 42.6553 14.8556 40.9811 15.1145C41.6825 15.5535 42.0929 16.1204 42.2157 16.8314C42.3418 17.5457 42.2222 18.4008 41.9992 19.4755C41.7988 20.4749 41.718 21.5594 41.9637 22.644C42.0994 23.2436 42.3418 23.7219 42.6036 24.0627C42.8654 24.4067 43.25 24.7246 43.7736 24.9932C42.5228 25.0522 41.2785 25.0817 40.0471 25.0817C39.5914 24.7508 39.2714 24.4035 39.0484 24.0693L39.0452 24.0725ZM36.8604 13.0175C37.3032 13.1256 37.7427 13.2305 38.1823 13.332C38.8578 13.4893 39.4169 13.4664 39.8403 13.2534C40.2604 13.0404 40.5675 12.5948 40.6547 11.8707C40.7291 11.2449 40.7291 10.5863 40.6353 9.87528C40.5319 9.09219 40.2281 8.44998 39.8597 8.02731C39.488 7.60136 39.0129 7.34251 38.4635 7.26388C37.9625 7.19179 37.4583 7.10988 36.9509 7.02469C37.7977 9.50503 37.5714 11.3071 36.8604 13.0175Z" fill="#F9FBFC" />
                    <path d="M46.8762 3.43033C48.8736 9.03976 49.6557 12.6734 48.88 16.0778C48.4405 19.3674 46.7373 22.3949 49.5038 24.5411C48.2627 24.6754 47.0217 24.7901 45.7871 24.8753C42.7975 21.9231 44.8207 18.8563 45.5059 15.7174C46.5595 12.4375 45.9487 9.24618 43.6572 3.51552C44.7238 3.50241 45.7968 3.46964 46.873 3.42705L46.8762 3.43033Z" fill="#F9FBFC" />
                    <path d="M62.674 19.5902C62.9035 21.0155 62.4025 21.9329 61.4976 22.5227C60.5765 23.1158 59.3709 23.3943 57.5901 23.6269C55.8255 23.8464 54.1255 23.9284 52.6937 23.5647C51.2684 23.2042 50.3958 22.3327 50.4572 20.9827C50.6318 18.1125 51.7726 14.6 50.8709 10.2062C50.4895 8.16492 50.2472 6.36283 50.6802 4.9834C51.0972 3.59743 52.1669 2.8045 53.7506 2.83072C55.331 2.85365 56.6852 3.53845 57.7808 4.38707C58.8732 5.25863 59.6489 6.3661 60.1725 8.21407C60.3793 8.95129 60.5862 9.70818 60.7833 10.4683C59.7103 10.8746 58.6632 11.2187 57.6354 11.5004C57.49 10.6322 57.3154 9.75405 57.1183 8.87593C56.7498 7.27043 55.9677 6.57253 54.8882 6.74291C53.7958 6.91001 53.5405 7.88314 53.896 9.56401C54.9044 14.1577 54.2968 18.2534 54.3905 21.0221C54.4552 22.0214 55.3278 22.336 56.7304 22.1787C58.146 22.0018 58.7536 21.5365 58.6179 20.5339C58.5209 19.7901 58.4531 18.9415 58.3787 18.0109C59.6198 17.7652 60.8964 17.4474 62.2442 17.0443C62.4122 17.9618 62.5577 18.8202 62.6805 19.5935L62.674 19.5902Z" fill="#F9FBFC" />
                    <path d="M62.4025 28.0273C61.1873 28.2337 59.9785 28.4893 58.8344 28.8006C58.6211 27.8438 58.3303 26.9493 58.0135 26.0876C59.1706 25.701 60.3664 25.3668 61.5622 25.0751C61.8111 26.0352 62.1181 27.005 62.3993 28.0273H62.4025Z" fill="#F9FBFC" />
                    <path d="M62.3153 8.97751C62.7968 7.84383 63.7308 6.89035 65.5181 6.24487C67.286 5.60595 69.1541 5.62561 70.6925 6.31368C72.2309 7.00176 73.2198 8.28616 73.4202 9.95719C73.4493 10.1734 73.4719 10.3897 73.4913 10.6092C72.3731 10.6846 71.2451 10.8222 70.1075 11.0352C70.0622 10.7567 70.0105 10.4782 69.9491 10.2029C69.7972 9.51814 69.474 9.05615 69.0377 8.82679C68.6014 8.59743 68.0584 8.56466 67.4379 8.74487C66.8174 8.92508 66.4134 9.22325 66.213 9.61643C66.0094 10.0129 66.032 10.524 66.2162 11.1695C66.4425 11.9624 66.8464 12.5883 67.4573 13.0732C68.0681 13.5581 68.9084 14.0987 69.9233 14.7606C70.7668 15.334 71.4067 15.8976 71.8915 16.4087C72.3763 16.9198 72.7803 17.5719 73.1326 18.3746C73.4849 19.1774 73.682 20.1308 73.7887 21.2449C73.9664 23.1387 73.9406 24.6951 73.3879 25.7993C72.8385 26.9133 71.7558 27.4703 70.0105 27.526C68.2685 27.5915 66.7042 27.1951 65.4793 26.4087C64.2577 25.6158 63.4303 24.4854 63.204 22.8766C63.1491 22.4703 63.1038 22.0607 63.0651 21.6544C64.2997 21.3268 65.4955 21.0483 66.6525 20.8124C66.6687 21.3202 66.6978 21.8314 66.7495 22.3425C66.8917 23.7285 67.7191 24.3314 68.9408 24.2003C70.1786 24.0693 70.5664 23.2731 70.4274 21.7953C70.3402 20.8451 70.0816 20.0522 69.5807 19.4362C69.0765 18.8202 68.3428 18.1682 67.3022 17.562C66.436 17.0378 65.6829 16.6217 65.0786 16.2711C64.4742 15.9205 63.9151 15.4683 63.3818 14.9212C62.8453 14.374 62.4736 13.6892 62.2345 12.8373C61.824 11.3989 61.8402 10.1243 62.3153 8.98078V8.97751Z" fill="#F9FBFC" />
                    <path d="M83.6752 5.25864C84.3636 6.63151 84.703 7.86676 84.9034 8.95457C83.7592 8.72194 82.5925 8.50241 81.3967 8.31892C82.0463 11.1957 81.0412 14.0463 81.0347 16.9493C80.8699 19.9179 81.7005 22.9421 82.3436 27.6734C81.3547 27.5653 80.3463 27.4703 79.3153 27.3949C78.4298 23.0371 77.7607 19.9113 77.8545 16.8314C77.8351 13.8006 78.5784 10.8189 77.8868 7.91591C76.636 7.82744 75.3594 7.80451 74.0634 7.87004C73.7596 6.86742 73.2683 5.84841 72.3989 4.85234C76.3645 4.35103 80.1976 4.68852 83.6752 5.25864Z" fill="#F9FBFC" />
                    <path d="M88.8722 21.8838C89.0144 23.4696 89.6123 24.5116 90.6304 24.6361C91.6517 24.7901 92.1203 23.8563 92.0653 22.2704C91.6743 15.8386 94.7091 14.7115 91.5709 6.69048C92.5695 6.86086 93.5682 7.01814 94.5733 7.15248C97.6599 15.1571 95.1422 16.3366 94.9838 22.4572C94.958 24.374 94.5863 26.2449 93.927 27.5489C93.2709 28.8628 92.3433 29.5018 90.9083 29.2921C89.4766 29.0955 88.326 28.1158 87.4727 26.6217C86.626 25.1341 85.9861 23.3025 85.7533 21.3596C84.6222 14.99 88.1999 12.9945 84.9906 5.47816C86.1024 5.67476 87.1883 5.88118 88.2646 6.08432C91.419 13.9513 88.006 15.37 88.8722 21.8805V21.8838Z" fill="#F9FBFC" />
                    <path d="M101.981 7.77174C103.678 7.81761 105.048 8.55811 106.166 9.78681C107.275 11.009 108.199 12.6341 108.432 14.4329C108.952 18.2141 107.585 20.7599 106.987 24.3674C106.693 26.104 106.292 27.5686 105.694 28.5417C105.09 29.5181 104.169 30.0326 102.501 29.9932C100.75 29.9408 99.0431 29.8261 97.3916 29.6656C96.6386 23.5843 97.2494 20.7239 97.8926 17.8045C98.8363 14.7606 98.3935 13.0371 96.7581 7.41132C98.4582 7.58826 100.194 7.70621 101.984 7.76846L101.981 7.77174ZM100.09 25.7862C100.672 25.8812 101.263 25.9762 101.855 26.0647C102.372 26.1433 102.776 26.0024 103.08 25.6616C103.384 25.3242 103.571 24.7377 103.678 23.9644C104.159 20.0719 105.74 17.8078 105.1 14.0824C104.98 13.3517 104.686 12.7685 104.302 12.3949C103.917 12.0181 103.445 11.8117 102.909 11.7789C102.291 11.7396 101.677 11.697 101.066 11.6446C102.938 17.3327 100.006 19.1675 100.09 25.7829V25.7862Z" fill="#F9FBFC" />
                    <path d="M113.303 7.66034C114.062 12.9585 114.272 16.5955 113.58 20.0391C113.063 23.4238 111.651 26.6184 113.196 29.6328C112.045 29.7344 110.891 29.8196 109.738 29.8851C108.067 26.0679 109.696 22.9683 110.239 19.7475C110.979 16.4841 110.63 13.1027 109.524 7.77174C110.759 7.74225 112.019 7.69966 113.299 7.65706L113.303 7.66034Z" fill="#F9FBFC" />
                    <path d="M117.381 9.19048C118.335 7.84383 119.899 7.08695 121.877 7.12954C123.855 7.16886 125.464 7.83727 126.537 8.73832C127.604 9.65575 128.031 10.855 127.856 12.7619C127.446 16.9133 126.983 21.3301 126.57 24.5902C126.369 26.1138 125.613 27.1426 124.576 27.8307C123.525 28.5221 122.329 28.8497 120.688 29.0234C119.059 29.1839 117.562 29.1184 116.389 28.5352C115.219 27.9618 114.647 26.8412 114.87 25.3111C115.384 21.9985 116.321 18.4991 116.227 14.2265C116.189 12.2639 116.418 10.5339 117.381 9.19376V9.19048ZM120.358 27.0542C121.657 26.9461 122.339 26.4021 122.455 25.2324C122.749 22.0312 123.412 17.6931 123.848 13.2075C123.987 11.5824 123.292 10.8484 121.916 10.9762C120.532 11.1007 119.918 12.0411 119.87 13.6957C119.679 18.2239 118.881 22.2278 118.503 25.4913C118.38 26.6807 119.065 27.1525 120.358 27.0542Z" fill="#F9FBFC" />
                </svg>
            </Box>
        </>
    )
}

export default LogoFooter;