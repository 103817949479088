import { Box, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";

const Base = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} py={{xs: 12, md: 0}} display="flex" justifyContent="center" alignItems="center" sx={{width: "100vw", height: {xs: "100%", md: "100dvh"}, backgroundColor: "white"}}>
                    <Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 500, fontSize: {xs: "16px", md:"24px"}, textAlign: "center"}}>
                                {t('landing.exploreRoots')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 800, fontSize: {xs: "24px", md:"48px"}, textAlign: "center"}}>
                                {t('landing.createSolidBase')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={4} sx={{maxWidth: "100vw"}}>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/triangle.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                            <Parallax speed={6}>
                                <img alt="" src="/images/square.png" />
                            </Parallax>
                        </Box>
                        <Box mx={4} mt={4}>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: {xs: "14px", md:"20px"}, textAlign: "center", width: {xs: "100%", md: "900px"}}}>
                                {t('landing.useCreativeProcess')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Base;