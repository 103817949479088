import { Box, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import Cards from "./poweUp/cards";
import { useState, useRef, useEffect } from "react";

const PowerUp = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [select, setSelect] = useState(0);
    const carouselRef = useRef(null);

    useEffect(() => {
        const activeCard = carouselRef.current.querySelector(`#element_card_${select}`);
        if (activeCard) {
            const containerWidth = carouselRef.current.clientWidth;
            const cardWidth = activeCard.offsetWidth;
            const scrollLeft = activeCard.offsetLeft - (containerWidth - cardWidth) / 2;
            carouselRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        }
    }, [select]);

    const change = (index) => {
        setSelect(index);
    };

    return (
        <>
            <Box sx={{ position: "relative" }}>
                <Box m={0} p={0} display="flex" sx={{ width: "100vw", height: { xs: "100%" }, backgroundColor: "white", maxWidth: "100vw" }}>
                    <Box>
                        <Box mx={{ xs: 4, lg: 10 }} mt={{xs: 12, lg: 6}}>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: { xs: "24px", sm: "32px" }, textAlign: "start", lineHeight: { xs: "28px", sm: "38px" }, color: theme.vars.palette.text.main }}>
                                    Power Up!
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.secondary }}>
                                    {t('landing.gaming.powerUp.description')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box display={{xs: "block", lg: "flex"}} mt={{xs: 3, lg: 6}} justifyContent={{ xs: "start" }} sx={{ width: "100vw", height: "100%", overflowX: "hidden", scrollBehavior: "smooth" }} ref={carouselRef}>
                                <Box mr={{ xs: 0, lg: 2 }} ml={{ xs: 0, lg: 10 }} id="element_card_1">
                                    <Cards select={select} change={change} title={t('landing.gaming.powerUp.name_1')} text1={t('landing.gaming.powerUp.text1_1')} text2={t('landing.gaming.powerUp.text2_1')} index={1} />
                                </Box>
                                <Box mx={{ xs: 0, lg: 2 }} id="element_card_2">
                                    <Cards select={select} change={change} title={t('landing.gaming.powerUp.name_2')} text1={t('landing.gaming.powerUp.text1_2')} text2={t('landing.gaming.powerUp.text2_2')} index={2} />
                                </Box>
                                <Box mx={{ xs: 0, lg: 2 }} id="element_card_3">
                                    <Cards select={select} change={change} title={t('landing.gaming.powerUp.name_3')} text1={t('landing.gaming.powerUp.text1_3')} text2={t('landing.gaming.powerUp.text2_3')} index={3} />
                                </Box>
                                <Box mx={{ xs: 0, lg: 2 }} mr={{ xs: 0, sm: select === 4 ? 12 : 0 }} id="element_card_4">
                                    <Cards select={select} change={change} title={t('landing.gaming.powerUp.name_4')} text1={t('landing.gaming.powerUp.text1_4')} text2={t('landing.gaming.powerUp.text2_4')} index={4} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PowerUp;
