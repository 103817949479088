import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Embrace = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateParallaxOffset = (factor) => {
        return scrollPosition * factor;
    };

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} display={{xs: "none", lg: "flex"}} justifyContent="center" alignItems="center" sx={{width: "100vw", height: "100dvh", backgroundColor: "white"}}>
                    <Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.danger, fontWeight: 500, fontSize: "24px", textAlign: "center"}}>
                                {t('landing.acknowledgeTitle')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.danger, fontWeight: 800, fontSize: "48px", textAlign: "center"}}>
                                {t('landing.embraceTitle')}
                            </Typography>
                        </Box>
                        <Box mx={2}>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: "20px", textAlign: "center", width: {xs: "100%", md: "900px"}}}>
                                {t('landing.embraceDescription')}
                            </Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: {lg: "45%", xl:"50%"},
                                    left: {lg: "0%", xl:"0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.2)}px)`
                                }}
                            >
                                <img alt="" src="/images/arm_1.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {lg: "-45%", xl:"-55%"},
                                    right: {lg: "0%", xl:"0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.15)}px)`
                                }}
                            >
                                <img alt="" src="/images/arm_2.png" />
                            </Box> 
                        </Box>
                    </Box>
                </Box>
                <Box m={0} p={0} display={{xs: "flex", lg: "none"}} justifyContent="center" alignItems="center" sx={{width: "100vw", height: "100dvh", backgroundColor: "white"}}>
                    <Box px={4}>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.danger, fontWeight: 500, fontSize: "16px", textAlign: "center"}}>
                                {t('landing.acknowledgeTitle')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.danger, fontWeight: 800, fontSize: "24px", textAlign: "center"}}>
                                {t('landing.embraceTitle')}
                            </Typography>
                        </Box>
                        <Box mx={2}>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: "14px", textAlign: "center", width: {xs: "100%", md: "900px"}}}>
                                {t('landing.embraceDescription')}
                            </Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: {xs: "30%", sm: "50%", md: "40%"},
                                    left: {xs: "0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.2)}px)`
                                }}
                            >
                                <img alt="" src="/images/arm_1.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {xs: "-28%", sm: "-10%", md: "-15%"},
                                    right: {xs: "0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.04)}px)`
                                }}
                            >
                                <img alt="" src="/images/arm_2.png" />
                            </Box> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Embrace;