import { Box } from "@mui/joy";

const Arrow = () =>{

    return(
        <>
            <Box>
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1.51428L7.70711 6.80718C7.31658 7.1977 6.68342 7.1977 6.29289 6.80718L1 1.51428" stroke="#F9FBFC" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </Box>
        </>
    )
}

export default Arrow;