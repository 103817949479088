import React from 'react';

const StoreContext = React.createContext();

export const StoreContextProvider = (props) => {

    return (
        <StoreContext.Provider
            value={{

            }}
        >
            {props.children}
        </StoreContext.Provider>
    );
};

export default StoreContext;
