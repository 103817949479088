import { Box, useTheme } from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import GameModeCarrousel from "./carrousel/carrousel";
import { useRef } from "react";
import { motion } from 'framer-motion';

const GameMode = () => {

    const { t } = useTranslation();
    const [actualImage, setActualImage] = useState(1);
    const [select, setSelect] = useState(1);
    const carouselRef = useRef(null);
    const theme = useTheme();

    const move = (newPosition) => {
        if (newPosition >= 1 && newPosition <= 3) {
            setSelect(newPosition);
            if (carouselRef.current) {
                const breakpointValue = getBreakpointValue();
                carouselRef.current.scrollTo({
                    left: (newPosition - 1) * carouselRef.current.offsetWidth * breakpointValue,
                    behavior: "smooth"
                });
            }
        }
    };

    const getBreakpointValue = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < theme.breakpoints.values.sm) {
            return 1;
        }
        else if (width < theme.breakpoints.values.md) {
            return 0.90;
        }
        else if (width < theme.breakpoints.values.lg) {
            return 0.90;
        }
        else if (width < theme.breakpoints.values.xl) {
            return 0.70;
        }
        else {
            return 0.75;
        }
    };

    const getX = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < theme.breakpoints.values.xs) {
            return 0;
        }
        else if (width < theme.breakpoints.values.sm) {
            return 0;
        }
        else {
            return 10;
        }
    };

    return (
        <>
            <Box id="gameMode" sx={{ position: "relative", }}>
                <Box m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{ width: "100vw", height: { xs: "100%" }, backgroundColor: "white" }}>
                    <Box display={{ xs: "flex", sm: "none" }} sx={{ width: "100vw" }}>
                        <img alt="" src={`/images/gaming/game_mode/image_${actualImage}_xs.png`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                    </Box>
                    <Box display={{ xs: "none", sm: "block", md: "none" }} sx={{ width: "100vw" }}>
                        <img alt="" src={`/images/gaming/game_mode/image_${actualImage}_sm.png`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                    </Box>
                    <Box display={{ xs: "none", md: "block" }} sx={{ width: "100%", height: "100%" }}>
                        <img alt="" src={`/images/gaming/game_mode/image_${actualImage}_md.png`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                    </Box>
                </Box>
                <Box display="flex" mt={6} justifyContent={{ xs: "start" }} sx={{ width: "100vw", height: "100%", overflowX: "hidden", scrollBehavior: "smooth" }} ref={carouselRef}>
                    <motion.div
                        id="element_card_1"
                        initial={{ x: 0 }}
                        animate={{ x: select === 1 ? 0 : 0 }}
                        transition={{ duration: 1, type: 'tween' }}
                    >
                        <Box sx={{ filter: select !== 1 ? "blur(3px)" : "blur(0px)" }} mr={{ xs: 0, sm: 2 }} ml={{ xs: 0, sm: 10 }} id="element_card_1">
                            <GameModeCarrousel title={t('landing.gaming.gameMode.title_1')} subtitle={t('landing.gaming.gameMode.subtitle_1')} text1={t('landing.gaming.gameMode.text1_1')} text2={t('landing.gaming.gameMode.text2_1')} index={1} />
                        </Box>
                    </motion.div>
                    <motion.div
                        id="element_card_2"
                        initial={{ x: 0 }}
                        animate={{ x: select === 2 ? getX() : 0 }}
                        transition={{ duration: 1, type: 'tween' }}
                    >
                        <Box sx={{ filter: select !== 2 ? "blur(3px)" : "blur(0px)" }} mx={{ xs: 0, sm: 2 }} >
                            <GameModeCarrousel title={t('landing.gaming.gameMode.title_2')} subtitle={t('landing.gaming.gameMode.subtitle_2')} text1={t('landing.gaming.gameMode.text1_2')} text2={t('landing.gaming.gameMode.text2_2')} index={2} />
                        </Box>

                    </motion.div>
                    <motion.div
                        id="element_card_3"
                        initial={{ x: 0 }}
                        animate={{ x: select === 3 ? getX() : 0 }}
                        transition={{ duration: 1, type: 'tween' }}
                    >
                        <Box sx={{ filter: select !== 3 ? "blur(3px)" : "blur(0px)" }} mx={{ xs: 0, sm: 2 }} >
                            <GameModeCarrousel title={t('landing.gaming.gameMode.title_3')} subtitle={t('landing.gaming.gameMode.subtitle_3')} text1={t('landing.gaming.gameMode.text1_3')} text2={t('landing.gaming.gameMode.text2_3')} index={3} />
                        </Box>
                    </motion.div>
                </Box>
                <Box mt={{ xs: 2, sm: 6 }} sx={{ width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box mr={5}>
                        {
                            select === 1 ? (
                                <Box>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            ) : (
                                <Box sx={{ cursor: "pointer" }} onClick={() => move(select - 1)} >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            )
                        }
                    </Box>
                    <Box>
                        {
                            select === 1 ? (
                                <Box sx={{ cursor: "pointer" }}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                        <circle cx="5.99967" cy="6.00004" r="4.66667" fill="#F9FBFC" />
                                        <circle cx="6.00033" cy="5.99996" r="3.33333" fill="#133BFD" />
                                    </svg>

                                </Box>
                            ) : (
                                <Box sx={{ cursor: "pointer" }} onClick={() => move(1)} >
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                    </svg>

                                </Box>
                            )
                        }
                    </Box>
                    <Box mx={3}>
                        {
                            select === 2 ? (
                                <Box sx={{ cursor: "pointer" }}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                        <circle cx="5.99967" cy="6.00004" r="4.66667" fill="#F9FBFC" />
                                        <circle cx="6.00033" cy="5.99996" r="3.33333" fill="#133BFD" />
                                    </svg>

                                </Box>
                            ) : (
                                <Box sx={{ cursor: "pointer" }} onClick={() => move(2)}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                    </svg>

                                </Box>
                            )
                        }
                    </Box>
                    <Box>
                        {
                            select === 3 ? (
                                <Box sx={{ cursor: "pointer" }}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                        <circle cx="5.99967" cy="6.00004" r="4.66667" fill="#F9FBFC" />
                                        <circle cx="6.00033" cy="5.99996" r="3.33333" fill="#133BFD" />
                                    </svg>

                                </Box>
                            ) : (
                                <Box sx={{ cursor: "pointer" }} onClick={() => move(3)}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="6" fill="#133BFD" />
                                    </svg>

                                </Box>
                            )
                        }
                    </Box>
                    <Box ml={5}>
                        {
                            select === 3 ? (
                                <Box>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            ) : (
                                <Box sx={{ cursor: "pointer" }} onClick={() => move(select + 1)} >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default GameMode;