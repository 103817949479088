import { Box, Grid, Typography, useTheme } from "@mui/joy";
import './styles.css';

const GameModeCarrousel = ({ title, subtitle, text1, text2, index }) => {

    const theme = useTheme();

    return (
        <>
            <Box sx={{}}>
                <Grid container columns={2} sx={{ flexGrow: 1, width:{ xs: "100vw", sm: "676px", md: "900px", xl: "1300px"}, height: {xs: "100%"} }}>
                    <Grid mx={{xs: 4, sm: 0}} xs={2} sm={1} sx={{ background: theme.vars.palette.primary['100'], height: {xs: "236px", sm: "445px", md: "358px"} }}>
                        <Box>
                            <Box sx={{ p: { xs: "24px 32px 24px 32px", sm: "24px 32px 24px 32px", md: "24px 32px 24px 32px" }}}>
                                <Box>
                                    <Box display={{ xs: "flex", sm: "none" }}>
                                        <img alt="" src={`/images/gaming/game_mode/carrousel_1_xs.png`} />
                                    </Box>
                                    <Box display={{ xs: "none", sm: "block", md: "none" }}>
                                        <img alt="" src={`/images/gaming/game_mode/carrousel_1_sm.png`} />
                                    </Box>
                                    <Box display={{ xs: "none", md: "block" }}>
                                        <img alt="" src={`/images/gaming/game_mode/carrousel_1_md.png`} />
                                    </Box>
                                </Box>
                                <Box mt={1}>
                                    <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: theme.vars.palette.text.main }}>
                                        {title}
                                    </Typography>
                                </Box>
                                <Box mt={1}>
                                    <Typography fontFamily="Raleway" sx={{ fontWeight: 500, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.main }}>
                                        {subtitle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid mx={{xs: 4, sm: 0}} xs={2} sm={1} sx={{ height: {xs: "494px", sm: "405px", md: "358px"}, background: theme.vars.palette.background.commonWhite }}>
                        <Box sx={{ p: { xs: "12px 32px 12px 32px", sm: "24px 32px 24px 32px", md: "24px 32px 24px 32px" } }}>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 500, fontSize: "14px", textAlign: "start", lineHeight: "21px", color: theme.vars.palette.text.secondary }}>
                                    {text1}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography mr={1} fontFamily="Raleway" sx={{ fontWeight: 500, fontSize: "14px", textAlign: "start", lineHeight: "21px", color: theme.vars.palette.text.secondary }}>
                                    {text2}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default GameModeCarrousel;