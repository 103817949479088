import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Banner = () => {

    const theme = useTheme();
    const { t } = useTranslation();

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const bannerTitleElement = document.getElementById("banner-title");
            const bannerTitlePosition = bannerTitleElement.getBoundingClientRect().top;
            if(bannerTitlePosition <= -256){
                const scrollPosition = window.scrollY;
                const levelOffsetTop = document.getElementById("game").offsetTop;
                setIsSticky((bannerTitlePosition <= -256) ? (scrollPosition > levelOffsetTop) ? false : true : false);
            }
            else{
                setIsSticky(false)
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <Box id="banner" sx={{ position: "relative" }}>
                <Box m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{ width: "100vw", height: "100dvh" }}>
                    <Box display={{ xs: "flex", md: "none" }}>
                        <video
                            autoPlay
                            loop
                            muted
                            style={{
                                width: "100vw",
                                height: "100dvh",
                                objectFit: "cover",
                            }}
                        >
                            <source src="/videos/cut_hd_horizontal.mp4" type="video/mp4" />
                            Tu navegador no admite el elemento de video.
                        </video>
                    </Box>
                    <Box display={{ xs: "none", md: "block", xl: "none" }}>
                        <video
                            autoPlay
                            loop
                            muted
                            style={{
                                width: "100vw",
                                height: "100dvh",
                                objectFit: "cover",
                            }}
                        >
                            <source src="/videos/cut_hd.mp4" type="video/mp4" />
                            Tu navegador no admite el elemento de video.
                        </video>
                    </Box>
                    <Box display={{ xs: "none", xl: "block" }}>
                        <video
                            autoPlay
                            loop
                            muted
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        >
                            <source src="/videos/cut_4k.mp4" type="video/mp4" />
                            Tu navegador no admite el elemento de video.
                        </video>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "block", sm: "none" },
                            position: "absolute",
                            bottom: "0px",
                            right: { xs: "0%" }
                        }}
                    >
                        <Box>
                            <img alt="" src="/images/gaming/banner/banner_xs.png" />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", sm: "block", md: "none" },
                            position: "absolute",
                            bottom: "0px",
                            right: { sm: "0%" }
                        }}
                    >
                        <Box>
                            <img alt="" src="/images/gaming/banner/banner_sm.png" />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", md: "block" },
                            position: "absolute",
                            bottom: { md: "10px", xl: "20px" },
                            right: { md: "20%", xl: "28.5%" }
                        }}
                    >
                        <Box>
                            <img alt="" src="/images/gaming/banner/banner_md.png" />
                        </Box>
                    </Box>
                    {
                        isSticky && (
                            <Box sx={{
                                position: "fixed",
                                top: { xs: 60, sm: 80 },
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 200,
                            }}>

                                <Box display={{ position: "relative" }}>
                                    <Box>
                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            style={{
                                                width: "100vw",
                                                height: "100px",
                                                objectFit: "cover",
                                            }}
                                        >
                                            <source src="/videos/cut_4k.mp4" type="video/mp4" />
                                            Tu navegador no admite el elemento de video.
                                        </video>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: { xs: "block", sm: "none" },
                                            position: "absolute",
                                            bottom: "30px",
                                            left: { xs: "8%" },

                                        }}
                                    >
                                        <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "24px", lineHeight: "28px" }}>
                                            {t('landing.gaming.banner.title')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: { xs: "none", sm: "block", md: "none" },
                                            position: "absolute",
                                            bottom: "30px",
                                            left: { sm: "5%" }
                                        }}
                                    >
                                        <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "28px", lineHeight: "32px" }}>
                                            {t('landing.gaming.banner.title')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: { xs: "none", md: "block" },
                                            position: "absolute",
                                            bottom: { md: "30px", xl: "30px" },
                                            left: { md: "5%", xl: "5%" },
                                            width: "100%",
                                            minWidth: { md: "550px", xl: "550px" },
                                        }}
                                    >
                                        <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "32px", lineHeight: "38.4px" }}>
                                            {t('landing.gaming.banner.title')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    <Box id="banner-title">
                        <Box>
                            <Box
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    position: "absolute",
                                    bottom: "30px",
                                    left: { xs: "8%" },

                                }}
                            >
                                <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "24px", lineHeight: "28px" }}>
                                    {t('landing.gaming.banner.title')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", sm: "block", md: "none" },
                                    position: "absolute",
                                    bottom: "30px",
                                    left: { sm: "5%" }
                                }}
                            >
                                <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "28px", lineHeight: "32px" }}>
                                    {t('landing.gaming.banner.title')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    position: "absolute",
                                    bottom: { md: "30px", xl: "30px" },
                                    left: { md: "5%", xl: "5%" },
                                    width: "100%",
                                    minWidth: { md: "550px", xl: "550px" },
                                }}
                            >
                                <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: "32px", lineHeight: "38.4px" }}>
                                    {t('landing.gaming.banner.title')}
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Banner;