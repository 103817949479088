import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";

const LevelCard = ({ title, text1, text2, index }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: { xs: "none", md: "block" }, backgroundColor: "white", m: {xs: 0, md: 4} }}>
                <Box>
                    <Grid container columns={4} sx={{ flexGrow: 1, }}>
                        <Grid md={1}>
                            <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "88px", textAlign: "start", lineHeight: "114px", color: theme.vars.palette.text.main }}>
                                    0{index}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid md={3}>
                            <Box display="flex" height="100%" justifyContent="start" alignItems="center">
                                <Box sx={{ maxWidth: "471px" }}>
                                    <Box>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "32px", textAlign: "start", lineHeight: "38px", color: theme.vars.palette.text.main }}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.secondary }}>
                                            {text1}
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.secondary }}>
                                            {text2}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Box>
                    <Grid p={4} container columns={4} sx={{ flexGrow: 1, height: { xs: "100%" } }}>
                        <Grid xs={4}>
                            <Box display="flex" height="100%" justifyContent="start" alignItems="center">
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "40px", textAlign: "start", lineHeight: "48px", color: theme.vars.palette.text.main }}>
                                    0{index}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={4}>
                            <Box display="flex" height="100%" justifyContent="start" alignItems="center">
                                <Box>
                                    <Box my={2}>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "20px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.main }}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.secondary }}>
                                            {text1}
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography fontFamily="Raleway" sx={{ fontWeight: 350, fontSize: "16px", textAlign: "start", lineHeight: "24px", color: theme.vars.palette.text.secondary }}>
                                            {text2}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default LevelCard;