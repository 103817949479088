import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Partner = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateParallaxOffset = (factor) => {
        return scrollPosition * factor;
    };

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} display={{xs: "none", lg: "flex"}} sx={{width: "100vw", height: "100%", backgroundColor: theme.vars.palette.background.body}}>
                    <Box>
                        <Box mx={4} mt={20}>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "24px"}}>
                                    {t('landing.whyOniricStudio')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 800, fontSize: "48px"}}>
                                    {t('landing.weBecomePartner')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "20px", width: {xs: "100%", md: "900px"}}}>
                                    {t('landing.extensionOfYourCompany')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "20px", width: {xs: "100%", md: "900px"}}}>
                                    {t('landing.goTheExtraMile')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={8} display="flex" justifyContent="center" sx={{width: "100vw"}}>
                            <img alt="" src="/images/computer_1.png" style={{width: "100%"}}/>
                        </Box>
                    </Box>
                </Box>
                <Box m={0} p={0} display={{xs: "flex", lg: "none"}} sx={{width: "100vw", height: "100%", backgroundColor: theme.vars.palette.background.body}}>
                    <Box>
                        <Box mx={4} my={10} maxWidth="100vw">
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "16px"}}>
                                    {t('landing.whyOniricStudio')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 800, fontSize: "24px"}}>
                                    {t('landing.weBecomePartner')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "14px"}} maxWidth={{xs: "350px", sm: "600px"}} >
                                    {t('landing.extensionOfYourCompany')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "14px"}} maxWidth={{xs: "350px", sm: "600px"}}>
                                    {t('landing.goTheExtraMile')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Box display={{xs: "flex", md: "none"}}>
                                <img alt="" src="/images/computer_2.png" style={{width: "100%"}} />
                            </Box>
                            <Box display={{xs: "none", md: "flex"}}>
                                <img alt="" src="/images/computer_1.png" style={{width: "100%"}}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        top: {xs: "-45%", md:"-60%"},
                        right: "0%",
                        transform: `translateY(${calculateParallaxOffset(0.1)}px)`
                    }}
                >
                    <Box>
                        <Box display={{xs: "none", lg: "flex"}}>
                            <img alt="" src="/images/plane.png" />
                        </Box>
                        <Box display={{xs: "flex", lg: "none"}}>
                            <img alt="" src="/images/plane_mobile.png" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Partner;