import { Box } from "@mui/joy";
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from "../components/gaming/header";
import Banner from "../components/gaming/banner";
import Worlds from "../components/gaming/worlds";
import GameMode from "../components/gaming/gameMode";
import Level from "../components/gaming/level";
import PowerUp from "../components/gaming/powerUp";
import Business from "../components/gaming/business";
import Services from "../components/gaming/services";
import ContactUs from "../components/gaming/contactUs";
import Footer from "../components/gaming/footer";

const Gaming = () => {

    return (
        <ParallaxProvider>
            <Box sx={{ backgroundColor: "white", overflowX: "hidden", scrollBehavior: "smooth", maxWidth: "100vw" }}>
                <Box>
                    <Header />
                </Box>
                <Box>
                    <Banner />
                </Box>
                <Box id="worlds">
                    <Worlds />
                </Box>
                <Box id="game">
                    <GameMode />
                </Box>
                <Box id="level">
                    <Level />
                </Box>
                <Box id="powerup">
                    <PowerUp />
                </Box>
                <Box>
                    <Business />
                </Box>
                <Box>
                    <Services />
                </Box>
                <Box>
                    <ContactUs />
                </Box>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </ParallaxProvider>
    )
};

export default Gaming;