import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import LevelCard from "./level/level";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";

gsap.registerPlugin(ScrollTrigger);

const Level = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const sectionRefs = useRef([]);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const sections = sectionRefs.current;
        const outerWrappers = sections.map((section) =>
            section.querySelector(".outer")
        );
        const innerWrappers = sections.map((section) =>
            section.querySelector(".inner")
        );
        const headings = sections.map((section) =>
            section.querySelector(".section-heading")
        );

        gsap.set(outerWrappers, { yPercent: 100 });
        gsap.set(innerWrappers, { yPercent: -100 });

        const pinSections = ScrollTrigger.batch(sections, {
            start: "top top",
            end: "+=100%",
            onEnter: (batch) => animateSection(batch, 1),
            onLeaveBack: (batch) => animateSection(batch, -1),
            pin: true,
            pinSpacing: false,
            scrub: true,
        });

        function animateSection(batch, direction) {
            const dFactor = direction === -1 ? -1 : 1;
            const tl = gsap.timeline();

            batch.forEach((section, index) => {
                const image = section.querySelector("img");
                const heading = section.querySelector(".section-heading");
                const outerWrapper = outerWrappers[index];
                const innerWrapper = innerWrappers[index];

                tl.to(image, { yPercent: -15 * dFactor }, 0)
                    .fromTo(
                        [outerWrapper, innerWrapper],
                        { yPercent: (i) => (i ? -100 * dFactor : 100 * dFactor) },
                        { yPercent: 0 },
                        0
                    )
                    .fromTo(image, { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)
                    .fromTo(
                        heading.querySelectorAll(".char"),
                        { autoAlpha: 0, yPercent: 150 * dFactor },
                        {
                            autoAlpha: 1,
                            yPercent: 0,
                            duration: 1,
                            ease: "power2",
                            stagger: { each: 0.02, from: "random" },
                        },
                        0.2
                    );
            });
        }

        return () => {
            pinSections.forEach((trigger) => trigger.kill());
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const bannerTitleElement = document.getElementById("banner-level");
            const bannerTitlePosition = bannerTitleElement.getBoundingClientRect().top;
            if (bannerTitlePosition <= 106) {
                const scrollPosition = window.scrollY;
                const levelOffsetTop = document.getElementById("powerup").offsetTop;
                setIsSticky((bannerTitlePosition <= 106) ? (scrollPosition > levelOffsetTop) ? false : true : false);
            }
            else {
                setIsSticky(false)
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box mt={2}>
            <Box id="banner-level" sx={{
                backgroundColor: theme.vars.palette.primary['100'],
                zIndex: 200,
                width: '100vw',
                height: "100px",
            }}>

                <Box>
                    <Box
                        sx={{
                            display: { xs: "block", sm: "none" },
                            bottom: "30px",
                            left: { xs: "8%" },
                            mx: { xs: 2, md: 10 },
                            pt: { xs: 4, md: 3 }
                        }}
                    >
                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: theme.vars.palette.text.main }}>
                            {t('landing.gaming.level.general')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", sm: "flex" },
                            bottom: "30px",
                            left: { sm: "5%" },
                            mx: { xs: 8, md: 10 },
                            pt: { xs: 4, md: 3 }
                        }}
                    >
                        <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "32px", textAlign: "start", lineHeight: "38px", color: theme.vars.palette.text.main }}>
                            {t('landing.gaming.level.general')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {
                isSticky && (
                    <Box sx={{
                        position: "fixed",
                        top: { xs: 60, sm: 80 },
                        backgroundColor: "rgba(240, 240, 240, 0.8)",
                        backdropFilter: "blur(10px) saturate(2)",
                        zIndex: 200,
                        width: '100vw',
                        height: "100px",
                    }}>

                        <Box>
                            <Box
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    bottom: "30px",
                                    left: { xs: "8%" },
                                    mx: { xs: 2, md: 10 },
                                    pt: { xs: 4, md: 3 }
                                }}
                            >
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: theme.vars.palette.text.main }}>
                                    {t('landing.gaming.level.general')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", sm: "flex" },
                                    bottom: "30px",
                                    left: { sm: "5%" },
                                    mx: { xs: 8, md: 10 },
                                    mt: 3
                                }}
                            >
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "32px", textAlign: "start", lineHeight: "38px", color: theme.vars.palette.text.main }}>
                                    {t('landing.gaming.level.general')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            }
            <Box sx={{ position: "relative", display: { xs: 'none', md: "block" } }}>
                {[1, 2, 3, 4].map((index) => (
                    <section key={index} ref={(el) => (sectionRefs.current[index - 1] = el)}>
                        <div className="outer">
                            <div className="inner">
                                <Grid
                                    container
                                    columns={7}
                                    sx={{
                                        flexGrow: 1,
                                        width: { xs: "100vw" },
                                        height: "663px",
                                    }}
                                >
                                    <Grid xs={7} md={3}>
                                        <Box
                                            display={{ xs: "none", md: "block" }}
                                            sx={{
                                                height: "663px",
                                                width: "100%",
                                                backgroundImage: `url(/images/level/level_${index}.jpg)`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center",
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={7} md={4}>
                                        <Box
                                            sx={{
                                                height: "663px",
                                                width: "100%",
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                                backgroundColor: "white"
                                            }}
                                        >
                                            <h2 className="section-heading">
                                                <SectionHeadingObserver index={index} t={t} theme={theme} />
                                            </h2>
                                            <LevelCard
                                                text1={t(`landing.gaming.level.text1_${index}`)}
                                                text2={t(`landing.gaming.level.text2_${index}`)}
                                                index={index}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>
                ))}
            </Box>
            <Box sx={{ display: { xs: 'block', md: "none" } }}>
                {[1, 2, 3, 4].map((index) => (
                    <Grid
                        key={index}
                        container
                        columns={7}
                        sx={{
                            flexGrow: 1,
                            width: { xs: "100vw" },
                        }}
                    >
                        <Grid xs={7}>
                            <Box
                                display={{ xs: "flex", sm: "none" }}
                                sx={{
                                    height: "250px",
                                    width: "100%",
                                    backgroundImage: `url(/images/level/level_${index}.jpg)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    mb: 2
                                }}
                            />
                            <Box
                                display={{ xs: "none", sm: "block", md: "none" }}
                                sx={{
                                    height: "500px",
                                    width: "100%",
                                    backgroundImage: `url(/images/level/level_${index}.jpg)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    mb: 4
                                }}
                            />
                        </Grid>
                        <Grid xs={7}>
                            <Box
                                sx={{
                                    width: "100%",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    backgroundColor: "white"
                                }}
                            >
                                <Box>
                                    <Typography
                                        fontFamily="Raleway"
                                        variant="display1"
                                        sx={{
                                            fontWeight: 800,
                                            fontSize: "32px",
                                            textAlign: "start",
                                            lineHeight: "38px",
                                            color: theme.vars.palette.text.main,
                                            transition: "opacity 0.5s ease",
                                            mx: 3
                                        }}
                                    >
                                        {t(`landing.gaming.level.title_${index}`)}
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <LevelCard
                                        text1={t(`landing.gaming.level.text1_${index}`)}
                                        text2={t(`landing.gaming.level.text2_${index}`)}
                                        index={index}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
};

const SectionHeadingObserver = ({ index, t, theme }) => {
    const [ref, inView] = useInView({ threshold: 0.5 });

    return (
        <div ref={ref}>
            <Typography
                fontFamily="Raleway"
                variant="display1"
                sx={{
                    fontWeight: 800,
                    fontSize: "32px",
                    textAlign: "start",
                    lineHeight: "38px",
                    color: theme.vars.palette.text.main,
                    opacity: inView ? 1 : 0,
                    transition: "opacity 0.5s ease",
                    m: 6
                }}
            >
                {t(`landing.gaming.level.title_${index}`)}
            </Typography>
        </div>
    );
};

export default Level;