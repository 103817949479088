import { Box, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Brands = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} display={{xs: "none", xl: "flex"}} sx={{width: "100vw", height: "100%", backgroundColor: "white"}}>
                    <Box>
                        <Box px={4} mt={5}>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 500, fontSize: "24px"}}>
                                    {t('landing.ourAssociates')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 800, fontSize: "48px"}}>
                                    {t('landing.brandsWeTakeCareOf')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box m={0} p={0} display={{xs: "flex", xl: "none"}} sx={{width: "100vw", height: "100%", backgroundColor: "white"}}>
                    <Box px={4}>
                        <Box my={4} maxWidth="100vw">
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 500, fontSize: "16px"}}>
                                    {t('landing.ourAssociates')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 800, fontSize: "24px"}}>
                                    {t('landing.brandsWeTakeCareOf')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box  display="flex" justifyContent={{xs: "start", xl: "center"}} pl={{xs: 0, sm: 2, xl: 0}} sx={{overflowX: "auto", width: "100vw", ml: {xs: 2, md: 0}, backgroundColor: "white"}}>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/gangverk.png" />
                        </Box>
                    </Box>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/Kukun.png" />
                        </Box>
                    </Box>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/dml.png" />
                        </Box>
                    </Box>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/muney.png" />
                        </Box>
                    </Box>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/muletta.png" />
                        </Box>
                    </Box>
                    <Box my={{xs: 8, md: 6, xl: 12}}  sx={{minWidth:{xs: "300px", lg: "220px"}, height: "56px", px: 2, mr: 2, backgroundColor: theme.vars.palette.neutral["50"]}}>
                        <Box display="flex" justifyContent="center" alignItems="center" height={56}>
                            <img alt="" src="/images/rtp.png" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Brands;