import { Box, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Worlds = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} display="flex" alignItems="center" sx={{width: "100vw", height: {xs: "100%"}, backgroundColor: "white", maxWidth: "100vw"}}>
                    <Box px={{xs: 4, sm: "60px", md: "80px"}} py={{xs: 4, sm: "60px", md: "80px"}} sx={{width:{ xs: "100%", sm: "586px"}}}>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.primary['800'], fontWeight: 800, fontSize: {xs: "16px", sm: "20px", md: "24px"}, lineHeight: "28px" }}>
                                {t('landing.gaming.worlds.title')}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.secondary, fontWeight: 300, fontSize: {xs: "16px", sm: "16px", md: "16px"}, lineHeight: "28px" }}>
                                {t('landing.gaming.worlds.subtitle')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Worlds;