import { Box, Typography } from "@mui/joy";
import Logo from "./icons/logo";
import Arrow from "./icons/arrow";

const Header = ({ scrollToBusiness }) =>{

    return(
        <Box sx={{position: "relative",}}>
            <Box position="absolute" m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{width: "100vw", height: "100dvh"}}>
                <Box>
                    <Logo />
                </Box>
            </Box>
            <Box m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{width: "100vw", height: "100dvh"}}>
                <Box display={{xs: "flex", md:"none"}}>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            width: "100vw",
                            height: "100dvh",
                            objectFit: "cover",
                        }}
                    >
                        <source src="/videos/cut_hd_horizontal.mp4" type="video/mp4" />
                        Tu navegador no admite el elemento de video.
                    </video>
                </Box>
                <Box display={{xs: "none", md:"block", xl: "none"}}>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            width: "100vw",
                            height: "100dvh",
                            objectFit: "cover",
                        }}
                    >
                        <source src="/videos/cut_hd.mp4" type="video/mp4" />
                        Tu navegador no admite el elemento de video.
                    </video>
                </Box>
                <Box display={{xs: "none", xl: "block"}}>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    >
                        <source src="/videos/cut_4k.mp4" type="video/mp4" />
                        Tu navegador no admite el elemento de video.
                    </video>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "60px",
                        right: {xs: "45%", md:"50%"}
                    }}
                >
                    <Box>
                        <img alt="" src="/images/eye.png" />
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "30px",
                        right: {xs: "38%", sm:"41%", md:"47.5%", lg: "48.5", xl: "48%"}
                    }}
                >
                    <Box>
                        <Box onClick={()=> scrollToBusiness()} display="flex" sx={{cursor: "pointer"}}>
                            <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 700, fontSize: "16px"}}>
                                See more
                            </Typography>
                            <Arrow />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Header;